import React, { useEffect, useState } from 'react';
import CustomSwitch from '../../../components/switch';
import ReactSelect, { components } from 'react-select';
import useLabels from '../../../hooks/use-labels';
import { useAddUserLabelMutation } from './access-api-slice';
import { toast } from 'react-toastify';
import Modal from '../../../components/modal';
import { VictorIcon } from '../../../assets/icons';
import { Simulate } from 'react-dom/test-utils';
import reset = Simulate.reset;

export const InputOption = ({
                              getStyles,
                              Icon,
                              isDisabled,
                              isFocused,
                              isSelected,
                              children,
                              innerProps,
                              ...rest
                            }: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles

  const style = {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div
        className="flex justify-center items-center border-[1.5px] border-[#4A4A4A] rounded-[5px] w-[17px] h-[17px] py-[1px] px-[2px] mx-2 mb-[3px]">
        <input type="checkbox" className="here peer sr-only" checked={isSelected} onChange={() => {
        }} />
        <VictorIcon className="opacity-0 peer-checked:opacity-100" />
      </div>
      {children}
    </components.Option>
  );
};

export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#F5F8FA',
    borderColor: '#F5F8FA',
    minHeight: '40px',
    height: '48px',
    borderRadius: 5,
    alignContent: 'center',
    boxShadow: state.isFocused ? null : null,
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: state.isFocused ? '#DCDCDC' : '#DCDCDC',
    },
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '40px',
    alignContent: 'center',
    color: '#656565',
    // fontWeight: 500,
    backgroundColor: '#F5F8FA',
    fontSize: '14px',
    lineHeight: '21.88px',
    padding: '6px',
    flexDirection: !state.isFocused ? 'column' : 'row',
  }),
  menu: (provided: any) => ({
    ...provided,
    border: '0px solid #fff',
    color: '#656565',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21.88px',
    borderRadius: 8,
    outline: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '5px 8px',
    border: '0px solid #fff',
    borderRadius: 8,
    outline: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#fff' : '#fff',
    cursor: 'pointer',
    padding: '9px',
    paddingRight: '5px',
    borderRadius: state.isSelected ? 30 : 0,
    '&::nth-child(n)': {
      border: '0.25px dashed #A5B5E4',
    },
    '&:hover': {
      background: state.isSelected ? '#F5F8FA' : '#F5F8FA',
      borderRadius: 30,
      // borderRadius: 50,
    },
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const t = {
  header: 'ایجاد دسترسی جدید',
  username: {
    label: 'نام کاربری',
    placeholder: 'نام کاربری یا شماره موبایل را وارد نمایید...',
  },
  label: {
    label: 'برچسب',
    placeholder: 'برچسب خود را انتخاب کنید...',
  },
  email: {
    label: 'ایمیل ',
    placeholder: 'ایمیل را وارد نمایید...',
  },
  subheader: 'دسترسی ها',
  view: {
    label: 'مشاهده قبوض ',
    placeholder: 'امکان مشاهده قبوض این برچسب',
  },
  add: {
    label: 'ایجاد قبض ',
    placeholder: 'امکان ایجاد قبض جدید در این برچسب',
  },
  pay: {
    label: 'پرداخت قبوض',
    placeholder: 'امکان پرداخت تکی و یکجا قبوض',
  },
  submit: 'ثبت دسترسی',
  cancel: 'انصراف',
};

interface iForm {
  label?: number;
  user_mobile_number: string;
  has_view_permission: boolean;
  has_payment_permission: boolean;
  has_insert_permission: boolean;
}

const AddPermissionsView = () => {
  const [open, setOpen] = useState(false);

  const { labels } = useLabels();
  const [form, setForm] = useState<iForm>({
    label: undefined,
    user_mobile_number: undefined,
    has_view_permission: false,
    has_payment_permission: false,
    has_insert_permission: false,
  });
  const [error, setError] = useState<iForm>({
    label: undefined,
    user_mobile_number: undefined,
    has_view_permission: undefined,
    has_payment_permission: undefined,
    has_insert_permission: undefined,
  });
  const [addUserLabel, { isLoading }] = useAddUserLabelMutation();

  const handleForm = ({ value, name }) => {
    setForm({ ...form, [name]: value });
  };

  const onSubmit = async () => {
    try {
      const id = form.label;
      const body = { ...form };
      delete body.label;
      if (form.label) {
        const res = await addUserLabel({ id, body }).unwrap();
        if (res?.status) {
          toast.success(res?.message);
          setOpen(!open);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validationHandler = async (onSubmit: any) => {
    let hasError = false;
    let errors: any = {};
    Object.keys(form).map((key: any) => {
      if (validator(form, key)) {
      } else {
        errors = { ...errors, [key]: 'این مقدار اجباری میباشد!' };
        hasError = true;
      }
    });
    setError({ ...errors });
    if (!hasError)
      onSubmit();
  };

  const validator = <T, K extends keyof T>(obj: T, key: K) => {
    return obj[key] !== undefined;
  };

  useEffect(() => {
    if (!open)
      setForm({
        label: undefined,
        user_mobile_number: undefined,
        has_view_permission: false,
        has_payment_permission: false,
        has_insert_permission: false,
      })
  }, [open]);

  return <>
    <button
      className="bg-primary text-white btn-rounded"
      onClick={() => setOpen(!open)}
    >
      + افزودن دسترسی جدید
    </button>
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={'bg-white border w-[700px] h-[550px] border-gray-200 rounded-lg mt-16 mx-12 py-5 px-10'}>
        <h1 className="mt-1">{t.header}</h1>
        <div className="flex justify-between align-items-center">
          <div className="mt-2 w-[300px]">
            <label htmlFor="username">{t.label.label}</label>
            <ReactSelect
              placeholder={t.label.placeholder}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: '#656565',
                },
              })}
              options={labels}
              styles={selectStyles}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(e: any) => {
                handleForm({ value: e.value, name: 'label' });
              }}
              isSearchable={false}
              noOptionsMessage={() => 'گزینه‌ای یافت نشد'}
              loadingMessage={() => 'در حال دریافت'}
              className={'mt-2 w-full'}
              components={{
                Option: InputOption,
              }}
            />
            <p className="text-red-400 text-sm m-1">{error['label']}</p>
          </div>
          <div className="mt-2 w-[300px]">
            <label htmlFor="username">{t.username.label}</label>
            <input
              className={'rounded-lg bg-[#F5F8FA] text-[14px] font-normal mt-2'}
              type="text"
              name="user_mobile_number"
              placeholder={t.username.placeholder}
              onChange={({ target: { value, name } }) => handleForm({ value, name })}
            />
            <p className="text-red-400 text-sm m-1">{error['user_mobile_number']}</p>
          </div>
        </div>
        <h1 className="mt-8">{t.subheader}</h1>
        <div className="p-4 flex justify-between mt-4">
          <div>
            <h3>{t.view.label}</h3>
            <label className="text-sm">{t.view.placeholder}</label>
          </div>
          <CustomSwitch enabled={form['has_view_permission']} defaultChecked={false}
                        onChange={(e) => handleForm({ value: e, name: 'has_view_permission' })} />
        </div>
        <div className="p-4 flex justify-between">
          <div>
            <h3>{t.pay.label}</h3>
            <label className="text-sm">{t.pay.placeholder}</label>
          </div>
          <CustomSwitch enabled={form['has_payment_permission']} defaultChecked={false}
                        onChange={(e) => handleForm({ value: e, name: 'has_payment_permission' })} />
        </div>
        <div className="p-4 flex justify-between">
          <div>
            <h3>{t.add.label}</h3>
            <label className="text-sm">{t.add.placeholder}</label>
          </div>
          <CustomSwitch enabled={form['has_insert_permission']} defaultChecked={false}
                        onChange={(e) => handleForm({ value: e, name: 'has_insert_permission' })} />
        </div>
        <div className="p-4 flex justify-end mt-4">
          <button
            className="bg-[#181C32] text-white rounded-3xl py-2 px-6 mx-2"
            disabled={isLoading} onClick={() => validationHandler(onSubmit)}
          >{t.submit}</button>
          <button onClick={() => setOpen(!open)}
                  className="border border-[#181C32] text-[#181C32] rounded-3xl py-2 px-6">{t.cancel}</button>
        </div>
      </div>
    </Modal>
  </>;
};

export default AddPermissionsView;