import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { apiSlice } from '../services/api-slice';
import authReduser from 'src/features/auth/auth-slice';
import labelsReduser from 'src/features/labels/labels-slice';
import reportReduser from 'src/features/reports-bill/reports-bill-slice';
import managementReduser from 'src/features/management/management-slice';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!');
    // @ts-ignore
    toast.error(action?.payload?.data?.message || 'Server error');
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReduser,
    labels: labelsReduser,
    reports: reportReduser,
    management: managementReduser,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware).concat(rtkQueryErrorLogger),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<any>;
