import classNames from 'classnames';
import { dateFormat } from 'src/utils/helpers';
import s from './inquiry.module.css';
import { LoadingIcon } from '../../../../assets/icons';
import { useLazyBillRenewQuery } from '../../management-api-slice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function HistoryInquiry({ id,isLoading, inquiryList, setSelected, selected ,details,getData}: any) {
  const [billRenew, { isLoading: billLoading }] = useLazyBillRenewQuery();
  const navigate = useNavigate();

  const handleRenew = async () => {
    try {
      await billRenew(id).unwrap();
      toast.success('استعلام با موفقیت انجام شد');
      getData()
    } catch (e) {
      console.log(e);
    }
  };
  const handlePrint = () => {
    localStorage.setItem('print',
      JSON.stringify({
        title:details?.bill_type,
        logo:details?.bill_type_logo,
        structure:details?.structure,
        response:details?.response,
      })
    )
    window.open('/printer','_blank')
  };

  if (isLoading) return (
    <div className="flex justify-center items-center h-96">
      <LoadingIcon />
    </div>
  );

  return (
    <div className={s.inquiry}>
      <div className={s.inquiryBill}>
        {inquiryList?.map((item: any) => <div
          className={classNames('parag-md', s.inquiryDate)}
          key={item.id}
          onClick={() => setSelected(item.id)}
        >
          <span
            className={selected === item?.id ? 'font-bold text-sm' : 'text-black'}>{dateFormat(item?.inquiry_time)}</span>
        </div>)}
      </div>
      <div className="flex flex-col w-full justify-between px-6 pt-4">
        <div className={s.wrapper}>
          {Object.keys(details?.structure || {})?.map((key: any, index: number) => {
            return (
              <div key={key + index} className="flex justify-between">
                {details.structure[key] &&
                  <span className="text-[#8F96BE] text-sm mb-2">{details.structure[key]}</span>}
                {details.response[key] && <span className="text-[#555555] text-sm">{details.response[key]}</span>}
              </div>
            );
          })}
        </div>
        <div className="text-left print:hidden">
          <button onClick={handleRenew} className={classNames(s.inquiryButton, 'parag-lg-b bg-primary text-white')}>
            {billLoading ? '...' : 'استعلام مجدد'}
          </button>
          <button onClick={handlePrint} className={classNames(s.inquiryButton, 'parag-lg-b bg-slate-50 mr-2')}>
            چاپ قبض
          </button>
        </div>
      </div>
    </div>
  );
}
