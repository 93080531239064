import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRequestOtpMutation, useChangePasswordMutation } from '../../auth/auth-api-slice';
import TextMessage from 'src/components/text-message';
import s from './forget.module.css';
import { CloseTwoIcon, EyeIcon, EyeSlashIcon, LoadingIcon } from 'src/assets/icons';
import Modal from '../../../components/modal';
import { toast } from 'react-toastify';

interface formData {
  password_confirm?: string;
  password?: string;
  mobile_number?: string;
  otp_code?: string;
}

function ForgetPassword() {
  const [requestOtp, { error: requestOtpError, isLoading: requestOtpIsLoading }] = useRequestOtpMutation();
  const [changePassword, {
    isLoading: changePasswordLoading,
  }] = useChangePasswordMutation();

  const [open, setOpen] = useState(true);
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState(1);
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<formData>({
    mode: 'onChange',
  });

  async function handleLogin(value: any) {
    if (step === 1)
      return;
    let body = {
      password: value.value?.password || value.password,
      password_confirm: value.value?.password_confirm || value.password_confirm,
      otp_code: value.value?.otp_code || value.otp_code,
    };
    try {
      await changePassword(body).unwrap();
      setModal(false)
      toast.success('تغییر رمز با موفقیت انجام شد');
    } catch (error) {
      console.log(error);
    }
  }

  async function requestOtpHandler() {
    if (Object.keys(errors).length || !watch('mobile_number'))
      return false
    let body = {
      mobile_number: watch('mobile_number'),
    };
    try {
      await requestOtp({ body, type: 'change_password' }).unwrap();
      setStep(2);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!modal)
      setStep(1)
  }, [modal]);

  return <>
    <button className={'btn-primary bg-[#009EF7]'} onClick={() => setModal(!modal)}>تغییر رمز عبور</button>
    <Modal open={modal} onClose={() => setOpen(false)}>
      <div className={'w-[400px] bg-white border rounded-lg'}>
        <div className={'flex justify-center items-center text-primary relative h-8 bg-slate-100 rounded-t-lg'}>
          <span className="paragraph-bx text-[#888484]">تغییر رمز عبور</span>
          <div onClick={() => setModal(!modal)}
               className={'absolute -top-3.5 -right-3.5 cursor-pointer bg-white rounded-full p-2 shadow'}>
            <CloseTwoIcon />
          </div>
        </div>
        <form className={s.loginForm} onSubmit={handleSubmit(handleLogin)}>
          {step === 1 ?
            <div className="parag-lg relative">
              <input
                {...register('mobile_number', {
                  required: {
                    value: true,
                    message: 'شماره موبایل الزامی می باشد',
                  },
                  pattern: {
                    value: /^(?:\+98|0)?9\d{9}$/,
                    message: 'شماره موبایل اشتباه است'
                  }
                })}
                name="mobile_number"
                type={'text'}
                className={`${s.formInput} placeholder:parag-lg`}
                title="شماره موبایل"
                placeholder="شماره موبایل خود را وارد نمایید"
              />
              {errors?.mobile_number?.message && <TextMessage msg={errors?.mobile_number?.message} type="error" />}
            </div>
            : ''}
          {step === 2 ? <>
            <div className="parag-lg relative">
              <input
                {...register('otp_code', {
                  required: {
                    value: true,
                    message: 'کد امنیتی الزامی می باشد',
                  },
                })}
                name="otp_code"
                type={'password'}
                className={`${s.formInput} placeholder:parag-lg`}
                title="کد امنیتی"
                placeholder="کد امنیتی ارسالی به شماره موبایل خود را وارد کنید"
              />
              {errors?.mobile_number?.message && <TextMessage msg={errors?.mobile_number?.message} type="error" />}
            </div>
            <div className="parag-lg relative">
              <input
                {...register('password', {
                  required: {
                    value: true,
                    message: 'رمز عبور الزامی می باشد',
                  },
                })}
                name="password"
                type={open ? 'password' : 'text'}
                className={`${s.formInput} placeholder:parag-lg`}
                title="رمز عبور"
                placeholder="رمز عبور خود را وارد نمایید"
              />
              <div onClick={() => setOpen(!open)} className="absolute left-4 top-3.5">
                {open ? <EyeIcon /> : <EyeSlashIcon />}
              </div>
              {errors?.password?.message && <TextMessage msg={errors?.password?.message} type="error" />}
            </div>
            <div className="parag-lg relative">
              <input
                {...register('password_confirm', {
                  required: {
                    value: true,
                    message: 'تکرار رمز عبور الزامی می باشد',
                  },
                })}
                name="password_confirm"
                type={open ? 'password' : 'text'}
                className={`${s.formInput} placeholder:parag-lg`}
                title="تکرار رمز عبور"
                placeholder="تکرار رمز عبور خود را وارد نمایید"
              />
              <div onClick={() => setOpen(!open)} className="absolute left-4 top-3.5">
                {open ? <EyeIcon /> : <EyeSlashIcon />}
              </div>
              {errors?.password_confirm?.message &&
                <TextMessage msg={errors?.password_confirm?.message} type="error" />}
            </div>
          </> : ''}
          {
            // @ts-ignore
            requestOtpError && <TextMessage msg={requestOtpError?.data?.message} type="error" />
          }
          {step === 1 ? <button type="submit" onClick={requestOtpHandler} className={s.loginButton}>
            {requestOtpIsLoading ? <LoadingIcon fill="white" /> : 'ارسال کد'}
          </button> : <button type="submit" onClick={() => {
          }} className={s.loginButton}>
            {changePasswordLoading ? <LoadingIcon fill="white" /> : 'تغییر رمز'}
          </button>}
        </form>
      </div>
    </Modal>
  </>;
}

export default ForgetPassword;
