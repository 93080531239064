import { columnsProps } from '../../../components/table-wrapper/types';
import AddLabelView from '../add';

export const columns: columnsProps[] = [
  { title: 'برچسب', field: 'label_name', hasSort: false },
  {
    title: 'توضیحات', field: 'label_description', hasSort: false,
  },
  {
    title: 'وضعیت', field: 'is_active', hasSort: false,
    renderCell: ({ is_active }) => is_active ? 'فعال' : 'غیر فعال',
  },
  {
    title: 'عملیات',
    field: '',
    hasSort: false,
    renderCell: (props,renew) => <AddLabelView item={props} renew={renew}/>,
  },
];