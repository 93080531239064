import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';

const labelsSlice = createSlice({
  name: 'labels',
  initialState: { labels: [], activeLabel: null },
  reducers: {
    setLabels: (state, action) => {
      state.labels = action.payload;
    },
    setActiveLabel: (state, action) => {
      state.activeLabel = action.payload;
    },
  },
});

export const { setLabels, setActiveLabel } = labelsSlice.actions;
export default labelsSlice.reducer;

export const selectLabels = (state: RootState) => state.labels.labels;
export const selectActiveLabel = (state: RootState) => state.labels.activeLabel;
