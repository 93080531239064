import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../sidebar';
import Header from './header';
import s from './layout.module.css';

function Layout() {
  const [open, setOpen] = useState(true);
  return (
    <div className={s.layout}>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      <Sidebar open={open} setOpen={setOpen} />
      <div className={`${s.root} ${open ? ' w-[calc(100vw-220px)] ' : ' w-[calc(100vw-85px)] '}`}>
        <Header />
        <main className="py-5 px-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Layout;
