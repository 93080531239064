import React, { useEffect, useState } from 'react';
import { useBillPaymentMutation, useGetBillInfoMutation, useGetPaymentMethodsMutation, useInitialPaymentMutation, useMakePaymentMutation, useWalletBillPaymentMutation } from '../management-api-slice';
import { priceFormatter } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import Modal from '../../../components/modal';
import { BillFailedIcon, BillSuccessIcon, CashPaymentIcon, IpgPaymentIcon } from '../../../assets/icons';
import { OtpInput } from 'reactjs-otp-input';
import { log } from 'console';

const bg = [
  <CashPaymentIcon />,
  <IpgPaymentIcon />,
];

interface ComponentProps {
  type: 'single' | 'all' | 'off';
  query?: any;
  value: object[];
  setValue: any;
  setExtraQuery?: any;
}

const PaymentManagement = ({ type, value, setValue, query }: ComponentProps) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(true);
  const [info,setInfo] = useState<any>()
  const [methodList,setMethodList] = useState<any>()
  const [selectedMethod,setSelectedMethod] = useState<any>()
  const [otp, setOtp] = useState('');
  const [body, setBody] = useState<any>({
    bill_ids: [],
    excluded_bills: [],
    label_ids: [],
    bill_type_ids: [],
    payment_method: undefined,
    select_all: false,
  });
  const [paymentObject, setPaymentObject] = useState<any>(undefined);

  const [getBillInfo] = useGetBillInfoMutation();
  const [getPaymentMethods] = useGetPaymentMethodsMutation()
  const [initialPayment] = useInitialPaymentMutation()
  const [makePayment] = useMakePaymentMutation()
  
  // update body object
  useEffect(() => {
    if (type !== 'off')
      setBody({
        ...body,
        bill_ids: type === 'single' ? value?.map(({ id }: any) => id) : [],
        excluded_bills: type === 'all' ? value.slice(1, value.length).map(({ id }: any) => id) : undefined,
        label_ids: query?.label_ids,
        bill_type_ids: query?.bill_type_id,
        payment_method: undefined,
        select_all: type === 'all' && !query?.label_ids?.length && !query?.bill_type_id?.length,
      });
  }, [query, value, type]);

  // get bill total amount and count
  useEffect(() => {
    const getInfo = async () => {
      try{
        setLoader(true)
        const res = await getBillInfo(body).unwrap()      
        setInfo(res?.data)
      }catch(e) {
        console.log(e);
      }finally{
        setLoader(false)
      }
    };
    
    if(type !== 'off' && (body?.bill_ids?.length || body?.select_all))
      getInfo()
  },[body])

  // get method list
  useEffect(() => {
    const getMethodList = async () => {
      try{
        setLoader(true)
        const res = await getPaymentMethods({}).unwrap()
        setMethodList(res?.data);
      }catch (e){
        console.log(e);
      }finally{
        setLoader(false)
      }
    }

    if(open)
      getMethodList()
  },[open])

  const submitInitialPayment = async () => {
    if(selectedMethod?.id)
      try{
        setLoader(true)
        const res = await initialPayment({...body,payment_method:selectedMethod?.id}).unwrap()
        setPaymentObject(res?.data);
        if(selectedMethod?.policy?.has_otp)
          setStep(2)
        else
          setStep(3)
      }catch (e){
        console.log(e);
      }finally{
        setLoader(false)
      }
      else
      toast.error('یک روش پرداخت انتخاب کنید.')
  }

  const submitMakePayment = async () => {
    if(otp.length > 3)
      try{
        setLoader(true)
        const res = await makePayment({
          payment_object:paymentObject?.id,
          payment_confirm_otp:otp
        }).unwrap()
        console.log('res======>',res);
        
        // TODO payment receipt
          setStep(4);
        
      }catch (e){
        setStep(5)
        console.log(e);
      }finally{
        setLoader(false)
      }
    else
      toast.error('لطفا کد ارسال شده را صحیح وارد کنید')
  }

  // close modal and set every things to default
  const handleClose = () => {
    setOpen(!open)
    setStep(1)
    setOtp('')
    setInfo({})
    setSelectedMethod({})
    setValue({
      type: 'off',
      value: undefined,
      filters:{}
    })
  }

  return <>
    {open ?
      <Modal open={open} onClose={handleClose}>

        {step === 1 ? <div
          className={'absolute left-0 top-0 bg-white w-[calc(100%-220px)] h-full z-50 flex flex-col justify-center items-center'}>
          <h2 className={'mb-[50px]'}>لطفا روش پرداخت را انتخاب نمایید.</h2>
          {methodList?.available_payment_methods?.map((payment_method:any) =>{
            const { is_active,image,name,slug, id } = payment_method
            return <button
              className={`border border-gray-100 rounded-full h-[75px] mb-2 w-[400px] flex px-4 items-center ${id === selectedMethod?.id ? 'border-primary border-2' : ''} ${is_active?'':'bg-[lightgray]'}`}
              onClick={() => setSelectedMethod(payment_method)} key={id} disabled={!is_active} 
            >
              <img src={image} alt="image" className='w-[40px] h-[40px]'/>
              <div className='w-full'>
                <p className={is_active?'text-right  mr-2 text-base':'text-right text-[#706F6F] mr-2 text-base'}>{name}</p>
                {slug === 'wallet' ? <div className='text-[gray] flex justify-between w-full'>
                  <span className="mr-2 text-sm">موجودی:</span>
                  <span className="mr-2 text-sm">{priceFormatter(methodList?.wallet_balance)}</span>
                </div>:''}
                {slug === 'pod_wallet' ? <div className='text-[gray] flex justify-between w-full'>
                  <span className="mr-2 text-sm">موجودی:</span>
                  <span className="mr-2 text-sm">{priceFormatter(methodList?.pod_balance)}</span>
                </div>:''}
              </div>
            </button>
          }
          )}
          <div className="flex justify-around items-center bg-primary text-white rounded-full h-[75px] w-[400px]">
            <p>مبلغ قابل پرداخت</p>
            <p>{priceFormatter(info?.total_amount)} ریال</p>
          </div>
          <div className={'mt-[35px] flex justify-center'}>
            <button className={'rounded-full h-[40px] px-8 mx-2 bg-primary text-white'}
              onClick={submitInitialPayment}
            >پرداخت
            </button>
            <button className={'rounded-full h-[40px] px-8 mx-2 border border-gray-300'}
                    onClick={handleClose}>انصراف
            </button>
          </div>
        </div> : ''}

        {step === 2 ? <div
          className={'absolute left-0 top-0 bg-white w-[calc(100%-220px)] h-full z-50 flex flex-col justify-center items-center'}>
          <div className='w-[525px] h-[525px] border-2 py-[24px] px-[9px] rounded-2xl'>
            <div className='flex items-center mb-[50px] mx-8'>
              <img src={selectedMethod?.image} className='w-[40px] h-[40px]' alt="logo"/>
              <h2 className={'text-lg font-bold mr-2'}>پرداخت از {selectedMethod?.id===2?'کیف پول':selectedMethod?.id===3?'کیف پاد':''} </h2>
            </div>
            <div className='flex justify-between my-4 mx-20'>
              <p>موجودی کیف پول</p>
              <p>{priceFormatter(info?.wallet_balance)} ریال</p>
            </div>
            <div className='flex justify-between my-4 mx-20'>
              <p>جمع قبوض</p>
              <p>{priceFormatter(info?.total_amount)} ریال</p>
            </div>
            <div className='w-full h-[1px] bg-[lightgray] '></div>
            <p className='flex justify-center font-semibold mt-12'>لطفا کد یکبار مصرف را جهت تایید پرداخت از طریق کیف پول وارد نمایید.</p>
            <div className='flex justify-center py-12' style={{direction:'ltr'}}>
              <OtpInput value={otp} inputStyle={{width:'60px', height:'60px'}} onChange={(value) => setOtp(value)} numInputs={4} separator={<div className='mx-4'></div>} />
            </div>
            <div className={'flex justify-center'}>
              <button 
                className={'rounded-full h-[40px] px-8 mx-2 bg-primary text-white'}
                onClick={submitMakePayment}
              >تایید و پرداخت 
              </button>
              <button 
                className={'rounded-full h-[40px] px-8 mx-2 border border-gray-300'}
                onClick={handleClose}>
                  بازگشت
              </button>
            </div>
          </div>
        </div> : ''}

        {step === 3 ?<>
          <div 
            className='absolute left-0 top-0 bg-white w-[calc(100%-220px)] h-full z-50 flex flex-col justify-center items-center'
          >
            <BillSuccessIcon/>
            <p className="my-2 text-xl text-green-600">
              شناسه واریز شما با موفقیت ایجاد شد!
            </p>
            <p className='my-2 text-base text-gray-600'>
              شناسه واریزی شما : {paymentObject?.pay_id}
            </p>
            <p className="my-2 text-base text-gray-600">
              لطفا از طریق شناسه واریز خود اقدام به پرداخت کنید
            </p>
            
            <button
              onClick={handleClose}
              className='rounded-full h-[40px] px-8 mx-2 bg-primary text-white text-base my-8'
            >مدیریت قبوض</button>
          </div>
        </>:''}

        {step === 4 || step === 5 ?<>
          <div 
            className='absolute left-0 top-0 bg-white w-[calc(100%-220px)] h-full z-50 flex flex-col justify-center items-center'
          >
            <div className='my-4'>
              {step === 4?
                <BillSuccessIcon/>:
                <BillFailedIcon/>
              }
            </div>
            
            {step === 4?
            <p className="my-2 text-xl text-green-600">
              پرداخت شما با موفقیت ثبت شد!
            </p>:
            <p className="my-2 text-xl text-red-600">
              پرداخت شما ناموفق بود!
            </p>}
            <p className='text-sm text-gray-700'>
              از منوی گزارش پرداخت می توانید جزئیات پرداخت را مشاهده کنید.
            </p>
            <button
              onClick={handleClose}
              className='rounded-full h-[40px] px-8 mx-2 bg-primary text-white text-base my-8'
            >مدیریت قبوض</button>
          </div>
        </>:''}

      </Modal>
      : ''} 
    {type !== 'off' && (
      <div
        className="absolute left-0 bottom-[60px] w-[calc(100%-40px)] mx-[20px] p-[7px] flex justify-between rounded-3xl bg-primary">
        <div className={'flex justify-between items-center'}>
          {info?.total_amount ? <p className={'text-white mx-2'}>جمع قبوض
            : {priceFormatter(info?.total_amount)} ریال</p> : ''}
          <p className={'text-white mx-2 text-sm'}>
            تعداد: {info?.bills_count} قبض
          </p>
        </div>
        <div>
          <button
            className={'bg-white text-primary text-sm rounded-3xl mx-1 px-12 py-2'}
            onClick={() => setOpen(value.length?!open:false)}
          >پرداخت
          </button>
        </div>
      </div>
    )}
  </>;
};

export default PaymentManagement;