import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { priceFormatter } from 'src/utils/helpers';

const PrinterComponent = () =>
  {    
    const location = useLocation()
    const {response,structure,logo,title} = JSON.parse(localStorage.getItem('print'))

    const isPrice = [
      'amount',
      'totalBillDebt'
    ]

    useEffect(() => {
      window.print();
      window.onafterprint = () => {
        window.close();  // This will only work if the tab was opened by window.open()
        localStorage.removeItem('print')
      };
    }, []);

    useEffect(() => {
      console.log(location.state)
      },[])
    return (
      <>
        <div className="flex justify-center">
          <div className=" flex  items-center bg-[#E4E9F7] h-[47px] w-[595px]">
            <img
              src={logo}
              alt="Logo"
              className="w-9 h-9 mb-1 rounded-full mr-4"
            />
            <h1 className="text-xs font-normal mr-4">{title?title:"رسید قبض"}</h1>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="w-[557px] h-[775px] mt-2 border  mx-5 rounded-xl">
            {Object.keys(response).map((key) => (
              <div key={key} className="flex justify-between  items-center border rounded-t-lg border-[#E4E9F7] py-2.5">
                <p className="pr-3.5 font-normal text-xs">{structure[key]} :</p>
                <p className="pl-3.5 font-normal text-xs">{isPrice.indexOf(key) !== -1 ? priceFormatter(response[key]) :response[key]}</p>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

export default PrinterComponent;
