import { apiSlice } from 'src/services/api-slice';

export const drawApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    billDetail: builder.query({
      query: (id) => ({
        url: `/bill/detail/${id}`,
        method: 'GET',
      }),
      providesTags: ['Bills'],
    }),
    historyBill: builder.mutation({
      query: (body) => ({
        url: '/bill/history/',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Bills'],
    }),
    historyBillChart: builder.mutation({
      query: (body) => ({
        url: '/bill/history/',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Bills'],
    }),

  }),
});

export const {
  useHistoryBillChartMutation,
  useBillDetailQuery,
  useHistoryBillMutation,
} = drawApiSlice;
