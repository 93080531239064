import { useEffect, useState } from 'react';
import Modal from 'src/components/modal';
import { ArrowDownIcon, ArrowUpIcon, CloseLineIcon, TickIcon } from 'src/assets/icons';
import { useLazyBulkInsertBillDetailQuery } from '../api-slice';
import Loading from '../../../components/loading';

export default function ExcelUploadDetailModal({ item }: any) {
  const [trigger, { data, isLoading }] = useLazyBulkInsertBillDetailQuery();
  const [showFailed, setShowFailed] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (item && open)
      trigger(item.id);
    return () => {
    };
  }, [item, trigger, open]);

  const renderShowFailed = () => {
    return (
      <table className="min-w-full border rounded-3xl text-xs mt-4">
        <thead>
        <tr className="text-[#949ABD] flex justify-around items-center font-medium h-10 border">
          <th className="w-[10%] h-[45px] items-center flex justify-around">ردیف</th>
          <th className="w-[30%] h-[45px] items-center flex justify-around">عنوان قبض</th>
          <th className="w-[30%] h-[45px] items-center flex justify-around">شناسه قبض</th>
          <th className="w-[29%] h-[45px] items-center flex justify-around">پیام خطا</th>
        </tr>
        </thead>
        <tbody className=" min-h-[50px] max-h-[350px] w-full flex overflow-auto flex-col divide-y divide-gray-200">
        {data?.data?.failed_data && data?.data?.failed_data.length > 0 ? (
          data?.data?.failed_data.map((item: any, idx: number) => (
            <tr key={item?._id} className="text-center w-full flex justify-around items-center">
              <td className="w-[10%] h-[45px] items-center flex justify-around">{idx + 1}</td>
              <td className="w-[30%] h-[45px] items-center flex justify-around">{item.caption}</td>
              <td className="w-[30%] h-[45px] items-center flex justify-around">{item.bill_id}</td>
              <td className="w-[30%] h-[45px] items-center flex justify-around">{item.error_message}</td>
            </tr>
          ))
        ) : (
          <tr className="border-t">
            <td className="font-medium text-slate-600 w-36 pr-2">اطلاعاتی یافت نشد</td>
          </tr>
        )}
        </tbody>
      </table>
    );
  };

  return <>
    <button
      onClick={() => {
        setOpen(true);
      }}
      className="btn-primary btn-rounded px-6 h-[27px]"
    >
      جزییات
    </button>
    <Modal open={open} onClose={() => setOpen(false)}>
      <Loading loading={isLoading}>
        <div className="relative my-6 w-[750px] mx-auto bg-white rounded-lg">
          <div className="px-10 py-4">
            <div className="pt-6 max-h-3/6">
              <h2 className="paragraph-b mb-2">جزییات آپلود فایل </h2>
              <div className="flex justify-between mb-2">
                <div className="flex items-center gap-2">
                  <TickIcon />
                  <p className="paragraph-sx">رکوردهای ثبت شده</p>
                </div>
                <p>{data?.data?.total_count}</p>
              </div>
              <div className="flex justify-between mb-2">
                <div className="flex items-center gap-2">
                  <TickIcon />
                  <p className="paragraph-sx">رکوردهای آپدیت شده</p>
                </div>
                <p>{data?.data?.count_success}</p>
              </div>
              <div className="flex justify-between mb-2">
                <div className="flex items-center gap-2">
                  <TickIcon />
                  <p className="paragraph-sx">جمع رکورد های ذخیره شده</p>
                </div>
                <p>{data?.data?.count_success}</p>
              </div>
              <div className="flex justify-between mb-2">
                <div className="flex items-center gap-2">
                  <CloseLineIcon stroke="red" />
                  <p className="paragraph-sx"> رکورد های دارای خطا</p>
                  {data?.data?.count_failed > 0 ? <span
                    className="flex items-center text-blue-500 text-xs cursor-pointer"
                    onClick={() => setShowFailed(!showFailed)}
                  >
              مشاهده جزییات
                    {showFailed ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </span> : ''}
                </div>
                <p>{data?.data?.count_failed}</p>
              </div>
              {showFailed && renderShowFailed()}
            </div>
          </div>

          <div className="flex items-center justify-start px-10 pb-6 gap-4">
            <button className="btn-outline btn-rounded px-10" type="button" onClick={() => setOpen(false)}>
              بازگشت
            </button>
          </div>
        </div>
      </Loading>
    </Modal>
  </>;
}
