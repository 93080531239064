export default function Empty({ title }: any) {
  return (
    <div className="flex justify-center items-center w-full h-[600px]">
      <div className="flex flex-col items-center gap-2">
        <img src="/images/empty-img-gray.png" alt="" width={64} height={64} />
        <p>{title}</p>
      </div>
    </div>
  );
}
