import React from 'react';
import ApexChartPie from './apex-chart-pie';
import s from './chart-pie.module.css';
import { priceFormatter } from '../../../utils/helpers';

export default function ChartPie({ data }: any) {
  return (
    <div className={s.sectionChartPie}>
      <ApexChartPie data={data?.chart_info || []} />
      <div className={s.chartPieHeader}>
        <div className="text-center pb-4 space-y-2.5">
          <p className="parag-lg text-gray-400">
            جمع کل قیمت : <span>{priceFormatter(data?.total_amount)} ریال</span>
          </p>
          <button className={`${s.chartPieButton} parag-lg-b`}>پرداخت قبوض</button>
        </div>
      </div>
    </div>
  );
}
