import { apiSlice } from 'src/services/api-slice';
import qs from 'qs';

export const labelsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getList: builder.mutation({
      query: (params) => {
        return {
          url: `given-permission-list/?${qs.stringify({...params}, {arrayFormat: 'repeat'})}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetListMutation } = labelsApiSlice;
