export default function Loading({ loading, children }: { loading: boolean, children: any }) {

  return <>
    {loading ?
      <div
        className="absolute left-0 top-[64px] w-[calc(100vw)] z-50 bottom-0 h-[calc(100vh-64px)] flex justify-center items-center bg-[#0000000d] rounded-lg">
        <p className="animate-pulse	text-xl text-white bg-[#181c32a6] rounded-3xl p-2 shadow-[0_0_22px_#181c32]">
          درحال دریافت اطلاعات
        </p>
      </div> : ''}
    <>{children}</>
  </>;
}

// box-shadow: 0px 0px 22px #181c32;
// background: #181c32a6;
// border-radius: 50px;
// padding: 5px;