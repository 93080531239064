import { useEffect, useState } from 'react';
import { useRequestProfileMutation } from './profile-api-slice';
import s from './profile.module.css';
import { priceFormatter } from '../../utils/helpers';
import { useLazyLabelQuery } from '../labels/api-slice';
import { selectActiveLabel } from '../labels/labels-slice';
import { useAppSelector } from '../../redux/hooks';
import YesIcon from '../../assets/img/yes.svg';
import NoIcon from '../../assets/img/no.svg';
import ForgetPassword from './change-password';
import { AccountIcon } from '../../assets/icons';

function ProfilePage() {
  const activeLabel = useAppSelector(selectActiveLabel);
  const [requestProfile] = useRequestProfileMutation();
  const [trigger, { data }] = useLazyLabelQuery(activeLabel?.value);
  const [profileState, setProfileState] = useState(undefined);
  const [permissionState, setPermissionState] = useState({
    view: false,
    pay: false,
    insert: false,
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    activeLabel && trigger(activeLabel?.value);
    return () => {
    };
  }, [activeLabel]);

  useEffect(() => {
    setPermissionState(data?.data?.permissions);
  }, [data]);

  async function getData() {
    try {
      const res = await requestProfile({}).unwrap();
      setProfileState(res);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={s.profile}>
      <div className={s.info}>
        <div  className="h-16 w-16 bg-[#E3E4EA] p-1 rounded-md flex items-center justify-around" >
          <AccountIcon height={'80%'} width={'80%'} />
        </div>
        <p className="text-white px-4 py-3">{profileState?.username}</p>
      </div>
      <div className="flex justify-between m-16 my-3">
        <div className="rounded-lg border-2 rounded border-[#F5F7FD] w-3/6 mr-1">
          <div className={'rounded-lg bg-[#F5F7FD] h-16 flex justify-between items-center px-4'}>
            <p className="text-sm">اطلاعات حساب کاربری</p>
            <ForgetPassword />
          </div>
          <p className={'p-2 text-sm'}>نام و نام خانوادگی
            : {profileState?.first_name} {profileState?.last_name || '-'}</p>
          <p className={'p-2 text-sm'}>ایمیل : {profileState?.email || '-'}</p>
          <p className={'p-2 text-sm'}>شماره موبایل : {profileState?.mobile_number || '-'} </p>
        </div>
        <div className="rounded-lg border-2 rounded border-[#F5F7FD] w-3/6 mr-1">
          <div className={'rounded-lg bg-[#F5F7FD] h-16 flex justify-between items-center px-4'}>
            <p className="text-sm">دسترسی ها</p>
          </div>
          <div className={'inline-grid grid-cols-2 gap-x-10 gap-y-3 p-6'}>
            <div className={'flex w-full'}>
              <img src={permissionState?.view ? YesIcon : NoIcon} />
              <p className={'mr-2'}>مشاهده قبوض</p>
            </div>
            <div className={'flex w-full'}>
              <img src={permissionState?.pay ? YesIcon : NoIcon} />
              <p className={'mr-2'}>پرداخت قبوض</p>
            </div>
            <div className={'flex w-full'}>
              <img src={permissionState?.insert ? YesIcon : NoIcon} />
              <p className={'mr-2'}>ایجاد قبض جدید</p>
            </div>
          </div>

        </div>
      </div>
      <div className="flex justify-between m-16 my-3">
        <div className={'rounded-lg bg-[#F5F7FD] h-24 flex flex-col justify-around items-center px-4 w-3/6 mr-1'}>
          <div className="flex flex-row justify-between w-full">
            <p className={'p-2 text-[#009EF7] text-sm'}>موجودی کیف پول</p>
            <p className={'p-2 text-lg'}>{priceFormatter(profileState?.now_balance)} ریال</p>
          </div>
          <div className="flex flex-row justify-between w-full">
            <p className={'p-2 text-xs'}>شناسه پرداخت: {profileState?.exclusive_pay_id || '-'}</p>
            <button className="btn-primary bg-[#009EF7]">افزایش موجودی</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
