import { CheckboxOffIcon, CheckboxOnIcon } from '../../assets/icons';

interface CheckboxProps {
  checked: boolean;
  onChange: any;
}

const Checkbox = ({ checked, onChange }: CheckboxProps) => {
  if (checked)
    return (
      <div className={'flex justify-center items-center'} onClick={onChange}>
        <CheckboxOnIcon  />
      </div>
    );
  else
    return (
      <div className={'flex justify-center items-center'} onClick={onChange}>
        <CheckboxOffIcon  />
      </div>
    );
};

export default Checkbox;