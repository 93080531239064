import TableWrapper from '../../../components/table-wrapper';
import {columns} from './columns';
import { useGetListMutation } from '../api-slice';
import useLabels from '../../../hooks/use-labels';
import AddPermissionsView from '../add';

const PermissionView = () => {
  const { labels } = useLabels();

  return <TableWrapper
      pagination={'online'}
      api={useGetListMutation}
      title={'مدیریت دسترسی'}
      AddComponent={AddPermissionsView}
      columns={columns}
      uniqKey={'label'}
      filterOptions={[
        {
          name: 'label_ids',
          placeholder: 'برچسب',
          type: 'multiselect',
          options:labels.map((e) => {return { name:e.label,value:e.value }})
        },
        {
          name: 'search',
          placeholder: 'جستجو کاربر',
          type: 'string'
        },
        {
          name: 'is_active',
          type: 'select',
          placeholder: 'وضعیت',
          options: [
            { name: 'همه', value: undefined },
            { name: 'فعال', value: true },
            { name: 'غیر فعال', value: false },
          ]
        },
      ]}
    />

};

export default PermissionView;