import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CloseTwoIcon } from 'src/assets/icons';
import HistoryPayment from './history-payment';
import s from './detail-modal.module.css';
import HistoryInquiry from './history-inquiry';
import { useGetInquiryHistoryMutation } from '../management-api-slice';
import Modal from '../../../components/modal';

export default function ManagementDetailModal({ id }: any) {
  const [toggle, setToggle] = useState(1);
  const [open, setOpen] = useState(false);
  const [getInquiryHistory, { isLoading }] = useGetInquiryHistoryMutation();
  const [inquiryList, setInquiryList] = useState([]);
  const [selected, setSelected] = useState();
  const [details, setDetails] = useState({ structure: {}, response: {}, payment_info: {} });

  useEffect(() => {
    setDetails(inquiryList.filter((item: any) => item.id === selected)[0]);
  }, [selected]);

  const getData = async () => {
    try {
      const res = await getInquiryHistory({ id, params: {} }).unwrap();
      if (res?.status) {
        setInquiryList(res?.data);
        setSelected(res?.data?.[0]?.id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (open)
      getData();
  }, [id, open]);

  return <>
    <button className={`${s.bodyButton} parag-md-b`} onClick={() => setOpen(!open)}>
      جزئیات قبض
    </button>
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={s.detailModal}>
        <div className={s.modalHeader}>
          <span className="paragraph-bx">جزئیات قبض</span>
          <div onClick={() => setOpen(!open)} className={s.iconClose}>
            <CloseTwoIcon />
          </div>
        </div>
        <div className="px-3">
          <div className={s.detailTabs}>
            <button
              onClick={() => setToggle(1)}
              className={classNames(
                toggle === 1
                  ? 'text-primary relative -bottom-0.5 border-b-[2px] rounded-none border-b-black'
                  : 'text-gray-150',
                'parag-md-b text-center text-sm pb-[2px] w-[155px] mr-4',
              )}
            >
              تاریخچه استعلامات
            </button>
            <button
              onClick={() => setToggle(2)}
              className={classNames(
                toggle === 2
                  ? 'text-primary relative -bottom-0.5 border-b-[2px] rounded-none border-b-black'
                  : 'text-gray-150',
                'parag-md-b text-center text-sm pb-[2px] w-[155px] ml-4',
              )}
            >
              تاریخچه پرداخت
            </button>
          </div>
          <div className={classNames(toggle === 1 ? 'block' : 'hidden')}>
            <HistoryInquiry id={id} isLoading={isLoading} getData={getData} setSelected={setSelected}
                            selected={selected}
                            details={details} inquiryList={inquiryList} />
          </div>
          <div className={classNames(toggle === 2 ? 'block' : 'hidden')}>
            <HistoryPayment id={id} data={details?.payment_info ? [details?.payment_info] : []} />
          </div>
        </div>
      </div>
    </Modal>
  </>;
}
