import { columnsProps } from '../../../components/table-wrapper/types';
import { dateFormatter } from '../../../utils/helpers';
import ExcelUploadDetailModal from '../detail';
// import AddLabelView from '../add';

export const columns: columnsProps[] = [
  { title: 'برچسب', field: 'label_name', hasSort: false },
  {
    title: 'تاریخ آپلود', field: 'file_upload_date', hasSort: false,
    renderCell: ({ file_upload_date }) => dateFormatter(file_upload_date),
  },
  { title: 'وضعیت', field: 'status', hasSort: false },
  { title: 'تعداد کل رکوردها', field: 'total_count', hasSort: false },
  {
    title: 'عملیات', field: '', hasSort: false,
    renderCell: (props,renew) => <ExcelUploadDetailModal item={props} renew={renew}/>,
  },
];