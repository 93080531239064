import TableWrapper from '../../../components/table-wrapper';
import { columns } from './columns';
import React from 'react';
import { useUploadsMutation } from '../api-slice';
import ExcelUploadModal from '../add';

const ExcelUploadListView = () =>
  <TableWrapper
    pagination={'online'}
    api={useUploadsMutation}
    title={'تاریخچه فایل های آپلود شده'}
    AddComponent={ExcelUploadModal}
    columns={columns}
    uniqKey={'label'}
  />

export default ExcelUploadListView;