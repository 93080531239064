import { apiSlice } from 'src/services/api-slice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: 'token/',
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation({
      query: (body) => ({
        url: 'logout/',
        method: 'POST',
        body,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (body) => ({
        url: 'otp-verify/',
        method: 'POST',
        body,
      }),
    }),
    requestOtp: builder.mutation({
      query: ({ type , body }) => ({
        url: `send-otp/${type}`,
        method: 'POST',
        body,
      }),
    }),
    verifyPodOtp: builder.mutation({
      query: (body) => ({
        url: 'pasargad-verify-login-otp/',
        method: 'POST',
        body,
      }),
    }),
    requestPodOtp: builder.mutation({
      query: ({ body }) => ({
        url: `pasargad-request-login-otp/`,
        method: 'POST',
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: 'change-password/',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useVerifyOtpMutation, useRequestOtpMutation, useChangePasswordMutation , useVerifyPodOtpMutation,useRequestPodOtpMutation } = authApiSlice;
