import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectActiveLabel, selectLabels, setActiveLabel, setLabels } from 'src/features/labels/labels-slice';

const useLabels = () => {
  const dispatch = useAppDispatch();
  const labels = useAppSelector(selectLabels);
  const activeLabel = useAppSelector(selectActiveLabel);

  useEffect(() => {
    const localLabels = localStorage.getItem('labels');
    const activeIndex = localStorage.getItem('activeItem');
    const response = localLabels !== 'undefined' ? localLabels : '[]';
    const labelItems = JSON.parse(response || '[]');
    const active = labelItems.find((item: any) => item.value === Number(activeIndex));

    dispatch(setActiveLabel(active));
    dispatch(setLabels(labelItems));
  }, [dispatch]);

  return { labels, activeLabel };
};

export default useLabels;
