import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'src/utils/constants';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const access = localStorage.getItem('access');
    if (access) {
      headers.set('authorization', `Bearer ${access}`);
    }
    return headers;
  },
});

// @ts-ignore
const baseQueryWithReauth = async (arge, api, extraOption) => {
  let result = await baseQuery(arge, api, extraOption);
  if (result?.error?.status === 401) {
    const refresh = localStorage.getItem('refresh');
    console.log('sending refresh token');
    const refreshResult = await baseQuery(
      {
        url: `token/refresh/`,
        method: 'POST',
        body: {
          refresh,
        },
      },
      api,
      extraOption,
    );

    if (refreshResult?.data) {
      // @ts-ignore
      localStorage.setItem('refresh', refreshResult?.data?.refresh);
      // @ts-ignore
      localStorage.setItem('access', refreshResult?.data?.access);
      // @ts-ignore
      localStorage.setItem('has_notification', refreshResult?.data?.has_notification);
      // // @ts-ignore
      // const transformLabels = refreshResult?.data?.company_list.map((item: any) => {
      //   return {
      //     value: item.label_id,
      //     label: item.label_name,
      //   };
      // });
      // localStorage.setItem('labels', JSON.stringify(transformLabels));
      result = await baseQuery(arge, api, extraOption);
    } else {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      window.location.href = '/login';
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Bills', 'Labels', 'Notif'],
  endpoints: () => ({}),
});
