import React, { useEffect, useState } from 'react';
import { filtersProps, TableWrapperProps } from './types';
import styles from './table-wrapper.module.css';
import Pagination from './pagination';
import Table from './table';
import Filter from './filter';
import Loading from '../loading';
import { Popover } from '@headlessui/react';
import { DownloadIcon, EXCELIcon, PDFIcon } from '../../assets/icons';
import { toast } from 'react-toastify';

const isModal = (length: number, width: number) => length >= 4 || width <= 1024;

const TableWrapper = (
  {
    api,
    title,
    AddComponent,
    rows,
    columns,
    uniqKey,
    selectable,
    pagination = 'online',
    filterOptions,
    downloadApi,
    downloadKey,
    size = 'large',
    extraQuery,
  }: TableWrapperProps,
) => {
  const [width] = useState(window.innerWidth);
  const [getApi, { isLoading }] = api();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({ total_page: 0, total_row: 0 });
  const [filters, setFilters] = useState<filtersProps>({
    page_number: 1,
    page_size: 10,
  });

  const getData = async () => {
    try {
      console.log(extraQuery);
      const res = await getApi({ ...filters, ...extraQuery }).unwrap();
      if (res?.status) {
        setData(res?.data);
        setTotal({ total_page: res.total_page, total_row: res.total_row });
      }
    } catch (e) {
      console.error(e);
    }
  };

  async function handleDownload(type: string) {
    const body = {
      ...filters,
      export_request: downloadKey,
      export_type: type === 'pdf' ? 1 : 2,
    };

    delete body.page_number
    delete body.page_size

    try {
      await downloadApi({body}).unwrap();
      toast.success('درخواست دانلود با موفقیت ارسال شد.لینک دانلود را از بخش اعلانات مشاهده نمایید.', { autoClose: false });
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (api)
      getData();
  }, [filters, api, extraQuery]);

  const orderHandler = (field: string) => {
    let _list = filters?.order_by || [];
    console.log(_list?.includes(field));
    if (!_list?.includes(field) && !_list.includes(`-${field}`))
      _list.push(field);
    else if (_list?.includes(field)) {
      _list = _list.filter(x => x !== field);
      _list.push(`-${field}`);
    } else if (_list?.includes(`-${field}`))
      _list = _list.filter(x => x !== `-${field}`);
    setFilters({ ...filters, order_by: [..._list] });
  };

  useEffect(() => {
    console.log(width >= 1024);
  }, [width]);

  useEffect(() => {
    selectable?.setValue({ ...selectable, query: filters });
  }, [filters]);

  useEffect(() => {
    if(selectable)
      if (selectable?.type !== 'off') {
        setFilters({ ...filters,page_number:1, filter_payment_available: true });
      }else {
        setFilters({ ...filters,page_number:1, filter_payment_available: false });
      }
  }, [selectable?.type]);

  return (
    <div className={`${styles.wrapper} ${size === 'small' ? ' h-[calc(100vh-200px)]' : ''}`}>
      <Loading loading={isLoading}>
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className={styles.header}>
              <h1 className={styles.title}>{title}</h1>
              {AddComponent ? <AddComponent /> : ''}
            </div>
            <div className={`${styles.container} ${size === 'small' ? ' h-[calc(100vh-270px)]' : ''}`}>
              <div className={'flex justify-between items-center'}>
                <div className={'flex'}>
                  {!isModal(filterOptions?.length, width) ? <Filter
                    filterOptions={filterOptions}
                    setFilters={setFilters}
                    filters={filters}
                  /> : ''}
                  {selectable?.setValue ? <div className={'flex item-center '}>
                    <button
                      className={`text-sm h-[40px] px-4 mb-3 ${selectable?.type === 'single' ? 'text-[#09A6FF]' : ''}`}
                      onClick={() => selectable?.type === 'off' ? selectable?.setValue({
                        ...selectable?.value,
                        type: 'single',
                        value: [],
                        query: filters
                      }) : selectable?.setValue({
                        ...selectable?.value,
                        type: 'off',
                        value: undefined,
                        filters:{}
                      })}
                    >
                      انتخاب قبض
                    </button>
                    <button
                      className={`text-sm h-[40px] px-4 mb-3 ${selectable?.type === 'all' ? 'text-[#09A6FF]' : ''}`}
                      onClick={() => selectable?.type === 'all' ? selectable?.setValue({
                        ...selectable?.value,
                        type: 'off',
                        value: undefined,
                        query: {}
                      }) : selectable?.setValue({
                        ...selectable?.value,
                        type: 'all',
                        value: [total?.total_row],
                        query: filters
                      })}
                    >
                      انتخاب همه
                    </button>
                  </div> : ''}
                </div>
                <div className={'flex items-center justify-between'}>
                  {isModal(filterOptions?.length, width) ? <Filter
                    filterOptions={filterOptions}
                    setFilters={setFilters}
                    filters={filters}
                  /> : ''}
                  {downloadApi ? <Popover className=" relative flex justify-center items-center z-40">
                    {({ open, close }) => (
                      <>
                        <Popover.Button className="flex justify-center text-sm pt-2 h-[40px] mb-3 px-4">
                          <DownloadIcon /><span className="text-sm mr-1">دانلود</span>
                        </Popover.Button>
                        <Popover.Panel className="absolute left-0 top-8 z-10">
                          <div className="bg-white w-40 rounded-lg border flex flex-col">
                            <button onClick={() => {
                              handleDownload('pdf');
                              close();
                            }} className="flex m-3 w-[100%-0.75rem] flex-row-reverse">
                              <PDFIcon /><span className="ml-2 text-[#535353]">PDF</span>
                            </button>
                            <hr className="text-dark" />
                            <button onClick={() => {
                              handleDownload('excel');
                              close();
                            }}
                                    className="flex m-3 w-[100%-0.75rem] flex-row-reverse">
                              <EXCELIcon /><span className="ml-2 text-[#535353]">Excel</span>
                            </button>
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </Popover> : ''}
                </div>
              </div>
              <Table
                loading={isLoading}
                renew={getData}
                setFilters={orderHandler}
                props={{ selectable, columns, uniqKey, rows: pagination === 'online' ? data : rows }}
              />
            </div>
          </div>
          <Pagination
            total={total}
            filters={filters}
            setFilter={setFilters}
          />
        </div>
      </Loading>
    </div>
  );
};
export default TableWrapper;