import React from 'react';
import s from './contact.module.css';

export default function Contant() {
  return (
    <div className={s.sectionContact}>
      <div className={s.contact}>
        <div className="w-[305px]">
          <p className="parag-lg text-gray-800">
            در صورت بروز هرگونه مشکل و یا درخواست راهنمایی با شماره های زیر تماس حاصل فرمایید.
          </p>
        </div>
        <div className={`${s.contactTitle} parag-lg`}>
          <span className="block">91039333 - 021</span>
          <span>91039333 - 021</span>
        </div>
      </div>
      <div className={s.contactLogo}>
        <img src="/images/logo192 1.svg" alt="" width={65} height={66} />
      </div>
    </div>
  );
}
