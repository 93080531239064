import Profile from './profile';
import Notification from './notification';
import s from './header.module.css';
import LabelsSelect from 'src/components/labels-select';
import { useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation()
  return (
    <div className={s.header}>
      <div className="w-64">
        {location.pathname === "/dashboard"?<LabelsSelect />:''}
      </div>
      <div className={s.headerMenu}>
        <Notification />
        <Profile />
      </div>
    </div>
  );
}

export default Header;
