import { Switch } from '@headlessui/react';

export default function CustomSwitch({ enabled, defaultChecked, title, onChange,...props }: any) {
  return (
    <Switch.Group>
      <div dir="ltr" className="flex items-center justify-end">
        <Switch.Label className="mx-2 paragraph-se">{title}</Switch.Label>
        <Switch
          {...props}
          checked={enabled}
          onChange={onChange}
          defaultChecked={defaultChecked}
          className={`${
            enabled ? 'bg-[#38A169]' : 'bg-gray-200'
          } relative inline-flex h-5 w-9 items-center rounded-full transition-colors focus:outline-none`}
        >
          <span
            className={`${
              enabled ? 'translate-x-[18px]' : 'translate-x-0.5'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}
