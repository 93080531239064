import { useEffect, useRef, useState } from 'react';
import s from './notification.module.css';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import { useNotificationsQuery } from 'src/features/notifications/notifications-api-slice';
import NotifItems from './notification';
import Empty from 'src/components/empty';

function Notification() {
  const [open, setOpen] = useState(false);
  const [notif, setNotif] = useState<any>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { data: notifs = [], isError, isLoading } = useNotificationsQuery({ is_read: selectedIndex === 1 });
  const menuRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: any) => {
    if (open && !menuRef.current?.contains(e.target)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
  useEffect(() => {
    const notific = localStorage.getItem('has_notification');
    setNotif(notific);
  }, []);

  function renderTable() {
    if (notifs?.data && notifs?.data.length > 0)
      return <NotifItems data={notifs?.data} onClick={() => setOpen(false)} />;
    return <Empty title="اطلاعاتی یافت نشد!" />;
  }

  if (isLoading) {
    return <p>در حال دریافت اطلاعات</p>;
  }

  if (isError && !notifs) {
    return <p>خطایی رخ داده</p>;
  }
  const tabs = ['پیام جدید', 'پیام‌ خوانده شده'];

  return (
    <div ref={menuRef}>
      <div
        className={classNames(
          !notif &&
            "before:content-[' '] before:bg-red-400 before:w-2 before:h-2 before:rounded-full relative before:absolute before:top-[2px] before:right-[2px]",
        )}
      >
        <img
          onClick={() => setOpen(!open)}
          src="/images/notification.svg"
          className="cursor-pointer"
          alt="اعلانات"
          width={24}
          height={24}
        />
      </div>
      {open && (
        <div className={s.notification}>
          <div className={s.tabs}>
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
              <Tab.List className="flex justify-start gap-2 border-b-slate-100 border-b-2 mb-4">
                {tabs.map((category) => (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      classNames(
                        'w-24 py-1 text-sm font-medium',
                        selected ? 'text-primary border-b-2 border-b-primary' : ' text-gray-500',
                      )
                    }
                  >
                    {category}
                    {category === 'پیام جدید' && (
                      <div className="text-white px-[3px] text-xs font-light rounded-full bg-red-400 inline mr-1">
                        {`${notifs?.total_unread}+` ?? ''}
                      </div>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>{renderTable()}</Tab.Panel>
                <Tab.Panel>{renderTable()}</Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      )}
    </div>
  );
}

export default Notification;
