import React, { useEffect, useState } from 'react';
import Select, { components, MenuListProps } from 'react-select';
import useLabels from 'src/hooks/use-labels';
import { useAppDispatch } from 'src/redux/hooks';
import { setActiveLabel } from 'src/features/labels/labels-slice';
import { useNavigate } from 'react-router-dom';

export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#F5F7FD',
    borderColor: '#F5F7FD',
    minHeight: '40px',
    height: '40px',
    borderRadius: 30,
    alignContent: 'center',
    boxShadow: state.isFocused ? null : null,
    padding: '8px',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '14px',
    '&:hover': {
      border: state.isFocused ? '1px solid #DCDCDC' : '1px solid #DCDCDC',
    },
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '40px',
    alignContent: 'center',
    padding: '6px',
    flexDirection: !state.isFocused ? 'column' : 'row',
  }),
  menu: (provided: any) => ({
    ...provided,
    border: '0px solid #fff',
    borderRadius: 8,
    outline: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '5px 8px',
    border: '0px solid #fff',
    borderRadius: 8,
    outline: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F5F8FA' : '#fff',
    color: '#393939',
    cursor: 'pointer',
    margin: '5px 0 0 0',
    padding: '10px 9px 7px 9px',
    borderRadius: state.isSelected ? 30 : 0,
    '&:hover': {
      background: state.isSelected ? '#F5F8FA' : '#F5F8FA',
      borderRadius: 30,
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none',
  }),
};

function LabelsSelect() {
  const dispatch = useAppDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { labels, activeLabel } = useLabels();

  const onChange = (e: any) => {
    setMenuIsOpen(false);
    localStorage.setItem('activeItem', e.value);
    dispatch(setActiveLabel(e));
  };

  useEffect(() => {
    if (labels?.length && !activeLabel) {
      localStorage.setItem('activeItem', labels[0]?.value);
      dispatch(setActiveLabel(labels[0]));
    }
  }, [labels,activeLabel]);

  if (labels.length === 0 && !activeLabel) {
    return <p>...</p>;
  }

  const MenuList = (props: MenuListProps) => {
    const navigate = useNavigate();

    return (
      <div>
        <components.MenuList {...props} className="parag-lg-b text-slate-900 text-center px-2">
          {props.children}
        </components.MenuList>
        <div
          className="text-center parag-lg-b relative py-4 mx-2 border-t-[0.25px] border-dashed border-slate-450 z-50"
        >
          <button
            className="bg-primary text-white btn-rounded"
            onClick={() => navigate("/labels?open")}
          >
            + افزودن برچسب جدید
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Select
        isRtl
        placeholder="انتخاب کنید"
        styles={selectStyles}
        onChange={onChange}
        options={labels}
        defaultValue={activeLabel}
        components={{ MenuList }}
        noOptionsMessage={() => 'گزینه‌ای یافت نشد'}
        loadingMessage={() => 'در حال دریافت'}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </>
  );
}

export default LabelsSelect;
