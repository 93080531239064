import { Provider } from 'react-redux';
import MainRoutes from 'src/routes';
import { store } from 'src/redux/store';

function App() {
  return (
    <Provider store={store}>
      <MainRoutes />
    </Provider>
  );
}

export default App;
