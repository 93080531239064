import ApexChart from './apex-chart';
import s from './chart-bar.module.css';
import Select from 'react-select';
import { useBillTypesQuery } from 'src/features/management/management-api-slice';
import { useState } from 'react';

export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#fff',
    borderColor: '#DCDCDC',
    minHeight: '30px',
    height: '30px',
    borderRadius: 30,
    alignContent: 'center',
    boxShadow: state.isFocused ? null : null,
    cursor: 'pointer',
    fontWeight: 500,
    LineHeight: '21.42px',
    fontSize: '14px',
    '&:hover': {
      borderColor: state.isFocused ? '#DCDCDC' : '#DCDCDC',
    },
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '5px 5px 5px 5px',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '40px',
    alignContent: 'center',
    flexDirection: !state.isFocused ? 'column' : 'row',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F5F8FA' : '#fff',
    color: '#656565',
    cursor: 'pointer',
    padding: '5px 8px 3px 8px',
    //borderBottom: '0.25px solid #A5B5E4',
    borderRadius: state.isSelected ? 30 : 0,
    '&:hover': {
      background: state.isSelected ? '#F5F8FA' : '#F5F8FA',
      borderRadius: 30,
      // borderRadius: 50,
      '&::after': {
        opacity: 0,
      },
    },
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export default function ChartBar({ data }: any) {
  const { data: billsTypes = [] } = useBillTypesQuery({});
  const [type,setType] = useState(1)

  const handleType = ({ id }) => {
    console.log(id);
    setType(id);
  }

  return (
    <div className={s.sectionChartBar}>
      <div>
        <div className={s.chartBarHeader}>
          <p className="parag-lg-b text-gray-800">نمودار پرداخت قبوض</p>
          <button className={s.chartBarButton}>خروجی اکسل</button>
        </div>
        <div className="px-3 mt-4">
          <form>
            <Select
              value={billsTypes.filter((item:any) => item.id === type)[0]}
              formatOptionLabel={(item) => <div className={'flex'}><img className="w-4 h-4 ml-2" src={item.logo} alt="item.logo" /><p>{item.name}</p></div>}
              defaultValue={type}
              options={billsTypes}
              styles={selectStyles}
              isSearchable={false}
              className="w-[200px] parag-lg"
              getOptionLabel={(item: any) => item.name}
              getOptionValue={(item: any) => item.name}
              placeholder="نوع قبض"
              noOptionsMessage={() => 'گزینه‌ای یافت نشد'}
              loadingMessage={() => 'در حال دریافت'}
              onChange={handleType}
            />
          </form>
        </div>
      </div>
      {data?.chart_info?.[`bill_type_${type}`]?<ApexChart
        data={data?.chart_info?.[`bill_type_${type}`] || []}
        type={billsTypes.filter((item: any) => item.id === type)[0]}
      />:<p className={'text-center mt-16'}>داده ای موجود نمی باشد</p>}
    </div>
  );
}
