import { apiSlice } from '../../services/api-slice';
import qs from 'qs';

export const drawApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBillsList: builder.mutation({
      query: (params) => {
        return {
          url: `/bill/?${qs.stringify({...params}, {arrayFormat: 'repeat'})}`,
        };
      }
    })
  })
});

export const {
  useGetBillsListMutation,
} = drawApiSlice;