import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';

const reportBillSlice = createSlice({
  name: 'reports',
  initialState: {
    filters: {
      chart_mode: false,
      page_number: 1,
      page_size: 10,
      // bill_ids: [], label_ids: [], bill_type_ids: [], date_added__gte: '', date_added__lte: ''
    },
  },
  reducers: {
    setReportsFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    updateReportsFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
  },
});

export const { setReportsFilters, updateReportsFilters } = reportBillSlice.actions;
export default reportBillSlice.reducer;

export const selectReportsFilters = (state: RootState) => state.reports.filters;
