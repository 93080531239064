import { columnsProps } from '../../../components/table-wrapper/types';
import { dateFormatter } from '../../../utils/helpers';
import { CloseRedIcon, TickIcon } from '../../../assets/icons';
import EditPermissionsView from '../edit';

export const columns: columnsProps[] = [
  { title: 'برچسب', field: 'label_name', hasSort: false },
  { title: 'ایمیل', field: 'user.email', hasSort: false },
  {
    title: 'شماره موبایل', field: 'user.mobile_number', hasSort: false,
  },
  {
    title: 'ایجاد قبض', field: 'has_insert_permission', hasSort: false,
    renderCell: ({ has_insert_permission }) => <span className="flex justify-center">
        {has_insert_permission ? <TickIcon /> : <CloseRedIcon />}
      </span>,
  },
  {
    title: 'مشاهده قبض', field: 'has_view_permission', hasSort: false,
    renderCell: ({ has_view_permission }) => <span className="flex justify-center">
        {has_view_permission ? <TickIcon /> : <CloseRedIcon />}
      </span>,
  },
  {
    title: 'پرداخت قبض', field: 'has_payment_permission', hasSort: false,
    renderCell: ({ has_payment_permission }) =>
      <span className="flex justify-center">
        {has_payment_permission ? <TickIcon /> : <CloseRedIcon />}
      </span>,
  },
  {
    title: 'وضعیت', field: 'is_active', hasSort: false,
    renderCell: ({ is_active }) => is_active ? 'فعال' : 'غیر فعال',
  },
  {
    title: 'تاریخ ایجاد',
    field: 'date_added',
    hasSort: true,
    renderCell: ({ date_added }) => dateFormatter(date_added),
  },
  { title: 'عملیات', field: '', hasSort: false,renderCell:(data:any,renew) => <EditPermissionsView data={data} renew={renew}/> },
];