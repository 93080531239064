import {
  BillsActiveIcon,
  BillsIcon,
  DashboardActiveIcon,
  DashboardIcon,
  LabelsActiveIcon,
  LabelsIcon,
  ReportsActiveIcon,
  ReportsIcon,
  SettingsActiveIcon,
  SettingsIcon,
  UploadActiveIcon,
  UploadIcon,
  NotificationIcon,
  NotificationMenuIcon

} from 'src/assets/icons';

export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: 'dashboard',
    label: 'داشبورد',
    path: '/dashboard',
    icon: <DashboardIcon />,
    activeIcon: <DashboardActiveIcon />,
  },
  {
    key: 'management',
    label: 'مدیریت قبض',
    path: '/management',
    icon: <BillsIcon />,
    activeIcon: <BillsActiveIcon />,
  },
  {
    key: 'reports',
    label: 'گزارشات',
    path: '/reports',
    icon: <ReportsIcon />,
    activeIcon: <ReportsActiveIcon />,
    item: [
      { key: 'reports-bill', label: 'گزارشات قبوض', path: '/reports-bill' },
      { key: 'reports-payment', label: 'گزارشات پرداخت', path: '/reports-payment', disable: true },
    ],
  },
  {
    key: 'excel-upload',
    label: 'آپلود گروهی اکسل',
    path: '/excel-upload',
    icon: <UploadIcon />,
    activeIcon: <UploadActiveIcon />,
  },
  {
    key: 'notifications',
    label: 'اعلانات',
    path: '/notifications',
    icon: <NotificationMenuIcon />,
    activeIcon: <NotificationIcon />,
  },
  {
    key: 'labels',
    label: 'مدیریت برچسب‌ها',
    path: '/labels',
    icon: <LabelsActiveIcon />,
    activeIcon: <LabelsIcon />,
  },
  {
    key: 'permissions',
    label: 'مدیریت دسترسی',
    path: '/permissions',
    icon: <SettingsIcon />,
    activeIcon: <SettingsActiveIcon />,
  },
];
