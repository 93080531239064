interface ITextMessage {
  msg: string;
  type: 'error' | 'success' | 'info';
}
const spanClass = 'mr-2 text-red-400 text-sanbod-pse text-[12px]';

function TextMessage({ msg, type }: ITextMessage) {
  if (type === 'error') return <span className={spanClass}>{msg}</span>;
  else if (type === 'info') return <span className={spanClass}>{msg}</span>;
  else return <span className="text-green-500">{msg}</span>;
}

export default TextMessage;
