import { useGetNotificationsMutation } from './notifications-api-slice';
import { useState } from 'react';
import TableWrapper from '../../components/table-wrapper';
import { dateFormatter } from '../../utils/helpers';
import NotifDetailModal from './notif-detail-modal';
import Modal from '../../components/modal';

function Notifications() {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState();

  return (
    <div className="flex flex-col h-[calc(100vh-104px)]">
      <Modal open={open} onClose={() => setOpen(false)}>
        <NotifDetailModal item={item} onClose={() => setOpen(false)} />
      </Modal>
      <TableWrapper
        title="لیست اعلانات"
        api={useGetNotificationsMutation}
        filterOptions={[
          {
            placeholder:'همه ی پیام ها',
            type:'select',
            name:'is_read',
            options:[
              {name:'همه',value:''},
              {name:'خوانده شده',value:true},
              {name:'خوانده نشده',value:false},
            ]
          },
          {
            name: 'date_from',
            placeholder: 'ازتاریخ',
            type: 'date',
          },
          {
            name: 'date_to',
            placeholder: 'تاتاریخ',
            type: 'date',
          },
          {
            placeholder:'جستجو',
            type:'string',
            name:'search'
          }
        ]}
        columns={[
          {field:'short_description',title:'متن پیام',renderCell:({is_read,short_description}) => <p className={!is_read?'font-bold':''}>{short_description}</p>},
          {field:'send_time',title:'تاریخ اعلان',hasSort:true,renderCell:({send_time,is_read}) => <p className={!is_read?'font-bold':''}>{dateFormatter(send_time * 1000)}</p>},
          {
            field: '', title: 'عملیات', renderCell: (item:any) => <button
              className="parag-md-b h-[30px] px-5 bg-primary rounded-full text-white"
              onClick={() => {
                setOpen(true);
                setItem(item);
              }}
            >
              مشاهده پیام
            </button>
          },
        ]}
      />
    </div>
  );
}

export default Notifications;
