import React from 'react';
import s from './payment.module.css';
import { dateFormatter, priceFormatter } from '../../../../utils/helpers';

export default function HistoryPayment({ data }: any) {
  return (
    <div className="px-8">
      {data?.length ? <table className={s.paymentTable}>
        <thead>
        <tr className={`parag-md ${s.headTr}`}>
          <th scope="col" className="rounded-s-full">
            تاریخ و زمان
          </th>
          <th scope="col">شناسه پرداخت</th>
          <th scope="col">مبلغ قبض</th>
          <th scope="col" className="rounded-e-full">
            رسید
          </th>
        </tr>
        </thead>
        <tbody className={s.tableBody}>
        {data?.map(({ request_time, pay_id, total_amount }) => <tr key={pay_id} className={`parag-md ${s.bodyTr}`}>
          <td className="w-[120px]">
            <span className={s.dateTd}>{dateFormatter(request_time)}</span>
          </td>
          <td className="text-sm">{pay_id}</td>
          <td className="text-sm">{priceFormatter(total_amount)} ریال</td>
          <td className="w-[120px]">
            <button className={`parag-md ${s.buttonTd}`}>رسید پرداخت</button>
          </td>
        </tr>)}
        </tbody>
      </table> : <div className="flex justify-around h-14 items-center">
        <p>تاریخچه وجود ندارد</p>
      </div>}

    </div>
  );
}
