import { ReactNode } from 'react';

interface ModalProps {
  open: boolean;
  onClose: any;
  children: ReactNode;
}

export default function Modal({ open = false, onClose, children }: ModalProps) {
  return (
    <>
      {open ? (
        <>
          <div 
            onClick={() => {onClose()}}
            className="fixed inset-0 flex items-center justify-center z-60"
          >
            <div onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </div>
          <div
            className="fixed inset-0 bg-black opacity-50 z-50"
            onClick={onClose}
          ></div>
        </>
      ) : null}
    </>
  );
}
