import { Routes, Route, useLocation, Navigate, Outlet, BrowserRouter } from 'react-router-dom';
import NotFound from 'src/components/404';
import Layout from 'src/components/layout';
import Login from 'src/features/auth/login';
import Dashboard from 'src/features/dashboard';
import LabelsListView from 'src/features/labels/list';
import Notifications from 'src/features/notifications';
import PermissionView from 'src/features/permissions/list';
import ProfilePage from '../features/profile';
import ExcelUploadListView from '../features/excel-upload/list';
import ReportBillListView from '../features/reports-bill/list';
import ManagementListView from '../features/management/list';
import ArrangeView from '../features/arrange';
import PrinterComponent from 'src/components/printer';

function RequireAuth() {
  let auth = localStorage.getItem('access');
  let location = useLocation();
  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

function Home() {
  return <Navigate to="/dashboard" />;
}

function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Layout />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/arrange" element={<ArrangeView />} />
            <Route path="/reports" element={<ReportBillListView />} />
            <Route path="/management" element={<ManagementListView />} />
            <Route path="/reports-bill" element={<ReportBillListView />} />
            <Route path="/excel-upload" element={<ExcelUploadListView />} />
            <Route path="/permissions" element={<PermissionView  />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/labels" element={<LabelsListView />} />
            <Route path="/profile" element={<ProfilePage />} />
          </Route>
        </Route>
        <Route path="/printer" element={<PrinterComponent />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;
