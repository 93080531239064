import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ArrowUpIcon, CircleGrayIcon, CircleWhiteIcon } from '../../assets/icons';
import classNames from 'classnames';
import s from './sidebar.module.css';

export default function SidbarLinks({ items, open }: any) {
  const [expand, setExpand] = useState(false);
  const { pathname } = useLocation();

  return (
    <li className={classNames(open ? 'duration-100 w-[190px]' : 'w-[48px] duration-300')}>
      <Link
        to={items.path}
        onClick={() => setExpand(!expand)}
        className={classNames(pathname.includes(items.path) ? s.linksActive : 'text-slate-300', s.itemsLink)}
      >
        <div>{pathname.includes(items.path) ? items?.activeIcon : items.icon}</div>
        <div className={classNames(open ? 'parag-lg' : 'scale-0 text-[0px]', s.itemsTitle)}>
          <span>{items.label}</span>
          {items.item && <ArrowUpIcon className={classNames(!expand ? 'rotate-180' : '', '')} />}
        </div>
      </Link>
      {items.item && items.item.length > 0 ? (
        <ul className={classNames(expand && open ? 'my-4' : 'scale-0 max-h-0', s.dropdownItems)}>
          {items.item.map((item: any) => (
            <li
              key={item.key}
              className={classNames(open ? '' : 'scale-0 text-[1px] max-h-0', s.dropdownItem, 'parag-lg')}
            >
              {item.disable ? (
                <Link
                  to="#"
                  className={classNames(pathname === item.path ? s.linkDeactive : s.linkDeactive, s.dropdownLink)}
                >
                  {pathname === item.path ? <CircleWhiteIcon /> : <CircleGrayIcon />}
                  <span>{item.label}</span>
                </Link>
              ) : (
                <Link to={item.path} className={classNames(pathname === item.path ? s.LinkActive : '', s.dropdownLink)}>
                  {pathname === item.path ? <CircleWhiteIcon /> : <CircleGrayIcon />}
                  <span>{item.label}</span>
                </Link>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
}
