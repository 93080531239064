export function saveLoginInfo(data: any) {
  localStorage.setItem('access', data?.access);
  localStorage.setItem('refresh', data?.refresh);
  localStorage.setItem('data', data?.user?.name);
  window.location.href = '/departments';
  document.cookie = `access=${data?.access};expires=0;path=/`;
  document.cookie = `refresh=${data?.refresh};expires=0;path=/`;
}

export function logOut() {
  localStorage.removeItem('refresh');
  localStorage.removeItem('access');
  localStorage.removeItem('data');
  document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  window.location.href = '/sign-in';
}

export function cn(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export const generateRandomKey = (length = 10) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export function priceFormatter(x: number) {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return '0';
}

export function dateFormatter(x: any) {
  // const date = new Date(x).toLocaleDateString('fa-IR', {
  //   weekday: 'long',
  //   day: '2-digit',
  //   month: 'long',
  //   year: 'numeric',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit',
  // });
  // return date;
  if (x == null) return '-';
  const d = new Date(x);
  if (d.toString() !== 'Invalid Date') {
    const res = d.toLocaleTimeString('fa-IR') + ' ' + d.toLocaleDateString('fa-IR');
    return res;
  } else {
    return '-';
  }
}

export function dateFormat(x: any) {
  const d = new Date(x);
  if (d.toString() !== 'Invalid Date') {
    const res = d.toLocaleDateString('fa-IR');
    return res;
  } else {
    return '-';
  }
}

export function status(key: string) {
  switch (key) {
    case 'done':
      return 'ثبت شده';

    case 'pending':
      return 'معلق';

    case 'doing':
      return 'در حال ثبت';

    default:
      return 'نامشخص';
  }
}

// const date = new Date(999954454544).toLocaleDateString('fa-IR', {
//   weekday: 'long',
//   day: '2-digit',
//   month: 'long',
//   year: 'numeric',
//   hour: '2-digit',
//   minute: '2-digit',
//   second: '2-digit',
// });
