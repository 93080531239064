import React from 'react';
import s from './notification.module.css';
import { Link } from 'react-router-dom';
import { dateFormatter } from 'src/utils/helpers';

function NotifItems({ data, onClick }: any) {
  return (
    <>
      <ul className={s.tabItems}>
        {data &&
          data.slice(0, 2).map((item: any, idx: number) => (
            <li key={item?._id} className={s.item}>
              <p dangerouslySetInnerHTML={{ __html: item?.short_description }}></p>
              <div className={s.title}>
                <span className="parag-lg">{dateFormatter(item?.notification_date)}</span>
              </div>
            </li>
          ))}
      </ul>
      <Link to="/notifications" onClick={onClick}>
        <div className={s.itemsLink}>مشاهده بیشتر</div>
      </Link>
    </>
  );
}

export default NotifItems;
