import React, { useState } from 'react';
import { PrinterIcon } from 'src/assets/icons';
import s from './popover.module.css';
import Modal from 'src/components/modal';
import BillReceipt from './receipt';
import { useBillDetailQuery } from '../api-slice';


export default function ReportsBillPopOver({ response,structure,bill_type_name,logo }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handlePrint = () => {
      localStorage.setItem('print',
        JSON.stringify({
          title:bill_type_name,
          logo:logo,
          structure:structure,
          response:response,
        })
      )
      window.open('/printer','_blank')
  };

  return (
    <>
      {/* <Modal open={open} onClose={() => setOpen(false)}>
        <BillReceipt id={id} onClose={() => setOpen(false)} />
      </Modal> */}
      {isOpen && (
        <ul className={`paragraph-sb ${s.itemsPopover} parag-md-b`}>
          <li
            onClick={() => {
              setOpen(false);
              setIsOpen(false);
            }}
          >
            <button className="flex" onClick={handlePrint}>
              <PrinterIcon />
              <span className="mr-2">پرینت قبض</span>
            </button>
          </li>
        </ul>
      )}
    </>
  );
}
