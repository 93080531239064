import { columnsProps } from '../../../components/table-wrapper/types';
import { dateFormatter, priceFormatter } from '../../../utils/helpers';
import { Popover } from '@headlessui/react';
import { ThreeDotsIcon } from '../../../assets/icons';
import ReportsBillPopOver from '../popover';

export const columns: columnsProps[] = [
  {
    title: 'نوع قبض', field: 'logo', hasSort: false,
    renderCell: ({logo}) => <img className={'m-auto'} src={logo} width={24} height={24} alt="" />,
  },
  { title: 'برچسب', field: 'label_name', hasSort: false },
  { title: 'عنوان', field: 'bill_type_name', hasSort: false },
  { title: 'شناسه قبض', field: 'bill_identifier', hasSort: false },
  { title: 'مبلغ قبض (ریال)', field: 'amount', hasSort: true,
    renderCell: ({ amount }) => priceFormatter(amount),
  },
  {
    title: 'تاریخ آخرین استعلام', field: 'inquiry_day', hasSort: true,
    renderCell: ({ inquiry_day }) => dateFormatter(inquiry_day),
  },
  {
    title: 'عملیات', field: '', hasSort: false,
    renderCell: ({response,structure,bill_type_name,logo}) => <Popover className={"relative flex justify-center items-center"}>
      <Popover.Button>
        <ThreeDotsIcon />
      </Popover.Button>
      <Popover.Panel className={"absolute left-20 top-0 z-10"}>
        <ReportsBillPopOver response={response} structure={structure} bill_type_name={bill_type_name} logo={logo} />
      </Popover.Panel>
    </Popover>,
  },
];