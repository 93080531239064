import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import React from 'react';

function ApexChartPie({ data }: any) {
  const series = data?.map((item: any) => item?.bill_type_total_amount || 0) || [];
  const options: ApexOptions = {
    chart: {
      width: 200,
      type: 'pie',
    },
    colors: data?.map((item: any) => item?.bill_type__color_code || 0) || [],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      offsetY: 30,
      width: 140,
      fontSize: '12px',
      fontFamily: 'Vazirmatn',
      fontWeight: 400,
      labels: {
        colors: '#718096',
        useSeriesColors: false,
      },
      markers: {
        offsetX: 7,
      },
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
      },
    },
    labels: data?.map((item: any) => item.bill_type__name) || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <div id="chart2" className="flex justify-between px-2">
      <div className="space-y-7 py-4 pr-1.5">
        <p className="parag-xl-b text-gray-800">جمع قبوض آماده پرداخت</p>
        <div className="parag-lg flex flex-col text-gray-300 space-y-3">
          {data ? data?.map((item: any, index: number) =>
            <span key={`${item.bill_type__name}-${index}`} className={'flex justify-start items-center ml-2'}>
                <span className={`flex justify-center items-baseline text-black ml-1`}>
                  <div
                    className={`w-2 h-2 rounded-full ml-1`}
                    style={{ backgroundColor: `${item?.bill_type__color_code}` }}
                  />
                  {item.bills_count}
                </span>
              {item?.bill_type__name}
            </span>
          ) : <p className={'text-center my-24'}>داده ای موجود نمی باشد</p>}
        </div>
      </div>
      <ReactApexChart options={options} series={series} height={240} width={220} type="pie" />
    </div>
  );
}

export default ApexChartPie;
