import { apiSlice } from 'src/services/api-slice';

export const drawApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    editUserLabel: builder.mutation({
      query: ({ id, body }) => ({
        url: `/label/edit-user-label/${id}`,
        method: 'PUT',
        body: body,
      }),
    })
  }),
});

export const {
  useEditUserLabelMutation,
} = drawApiSlice;
