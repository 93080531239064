import TableWrapper from '../../../components/table-wrapper';
import {columns} from './columns';
import React from 'react';
import { useGetLabelsMutation } from '../api-slice';
import AddLabelView from '../add';
import LabelExcelUploadModal from '../bulk';

const AddComponent = () => {
  return <div className='flex justify-center gap-2'>
    <LabelExcelUploadModal/>
    <AddLabelView/>
  </div>
}

const LabelsListView = () => {

  return <>
    <TableWrapper
      pagination={'online'}
      api={useGetLabelsMutation}
      title={'مدیریت برچسب‌ها'}
      AddComponent={AddComponent}
      columns={columns}
      uniqKey={'label'}
      filterOptions={[
        {
          name: 'search',
          placeholder: 'جست‌جو',
          type: 'string'
        },
        {
          name: 'is_active',
          type: 'select',
          placeholder: 'وضعیت',
          options: [
            { name: 'همه', value: undefined },
            { name: 'فعال', value: true },
            { name: 'غیر فعال', value: false },
          ]
        },
      ]}
    />
  </>
};

export default LabelsListView;