import React, { useEffect, useState } from 'react';
import Upload from 'rc-upload';
import { Line } from 'rc-progress';
import Modal from 'src/components/modal';
import { ArrowDownIcon, ArrowUpIcon, CloseLineIcon, FileIcons, InfoIcon, TickIcon } from 'src/assets/icons';
import CustomSwitch from 'src/components/switch';
import { useLazyBulkInsertBillDetailQuery } from '../api-slice';
import LabelsSelect from 'src/components/labels-select';
import useLabels from 'src/hooks/use-labels';
import { toast } from 'react-toastify';

export default function ExcelUploadModal() {
  const [open, setOpen] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [enabled, setEnabled] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState();
  const [recordCount] = useState();
  const [trigger, { data }] = useLazyBulkInsertBillDetailQuery();
  const [showFailed, setShowFailed] = useState(false);
  const [error, setError] = useState('');
  const { activeLabel } = useLabels();
  const [visible, setVisible] = useState(false);

  const access = localStorage.getItem('access');

  const props = {
    action: `${process.env.REACT_APP_API_URL}bulk-insert-bill/`,
    accept: '.png, .xls, .xlsx',
    type: 'drag',
    headers: {
      authorization: `Bearer ${access}`,
    },
    name: 'upload_file',
    // @ts-ignore
    data: { label_id: activeLabel?.value,type:1, default_active: enabled ? 1 : 0 },
    beforeUpload(file: any) {
      // if(!activeLabel?.value){
      //   setError('یک برچسب انتخاب کنید');
      //   return false
      // }
      setIsUploading(true);
      setFileName(file.name);
      // setFileSize(Math.floor(file.size / 1000));
    },
    onSuccess(res: any, file: { name: any }) {
      setError('');
      console.log('onSuccess', res, file.name);
      setIsUploading(false);
      toast.success(`فایل ${file.name} ذخیره گردید.`, { autoClose: false });
      setTimeout(() => {
        setOpen(false);
        // @ts-ignore
        setFileName();
        // setRecordCount(res?.data?.id);
      }, 2000);
    },
    onProgress(step: any) {
      console.log(step, 'step');
      setPercentage(Math.round(step.percent));
    },
    onError(err: any) {
      setError('خطایی رخ داده');
      console.log('onError', err);
    },
    onStart: (file: any) => {
      setError('');
      console.log('onStart', file.name);
    },
  };

  useEffect(() => {
    recordCount && trigger(recordCount);
    return () => {
    };
  }, [recordCount, trigger]);

  const handleToggle = () => {
    setVisible(!visible);
  };

  const renderShowFailed = () => {
    return (
      <table className="min-w-full border-2 rounded-md text-xs mt-4">
        <thead>
        <tr className="text-[#949ABD] font-medium h-10 border-2">
          <th className="px-3">ردیف</th>
          <th>نوع قبض</th>
          <th>شناسه قبض</th>
          <th>مبلغ قبض (ریال)</th>
        </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
        {data?.data?.failed_data && data?.data?.failed_data.length > 0 ? (
          data?.data?.failed_data.map((item: any, idx: number) => (
            <tr key={item?._id} className="text-center">
              <td className="w-2 py-3">{idx + 1}</td>
              <td>{item.caption}</td>
              <td>{item.bill_id}</td>
              <td>{item.bill_type_id}</td>
            </tr>
          ))
        ) : (
          <tr className="border-t">
            <td className="font-medium text-slate-600 w-36 pr-2">اطلاعاتی یافت نشد</td>
          </tr>
        )}
        </tbody>
      </table>
    );
  };

  const renderUploadData = () => {
    return (
      <div className="p-6">
        <h2 className="paragraph-b mb-2">جزییات آپلود فایل </h2>
        <div className="flex justify-between mb-2">
          <div className="flex items-center gap-2">
            <TickIcon />
            <p className="paragraph-sx">رکوردهای ثبت شده</p>
          </div>
          <p>{data?.data?.total_count}</p>
        </div>
        <div className="flex justify-between mb-2">
          <div className="flex items-center gap-2">
            <TickIcon />
            <p className="paragraph-sx">رکوردهای آپدیت شده</p>
          </div>
          <p>{data?.data?.count_success}</p>
        </div>
        <div className="flex justify-between mb-2">
          <div className="flex items-center gap-2">
            <TickIcon />
            <p className="paragraph-sx">جمع رکورد های ذخیره شده</p>
          </div>
          <p>{data?.data?.count_success}</p>
        </div>
        <div className="flex justify-between mb-2">
          <div className="flex items-center gap-2">
            <CloseLineIcon stroke="red" />
            <p className="paragraph-sx"> رکورد های دارای خطا</p>
            <span
              className="flex items-center text-blue-500 text-xs cursor-pointer"
              onClick={() => setShowFailed(!showFailed)}
            >
              مشاهده جزییات
              {showFailed ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </span>
          </div>
          <p>{data?.data?.count_failed}</p>
        </div>
        {showFailed && renderShowFailed()}
      </div>
    );
  };

  return <>
    <button
      className="border-2 text-primary border-primary px-5 h-[35px] rounded-full"
      onClick={() => setOpen(!open)}
    >
      آپلود گروهی قبض
    </button>
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="relative my-6 mx-auto bg-white rounded-lg w-[650px]">
        <div className={`px-10 pt-6 transition-opacity ease-in duration-700 ${recordCount ? 'hidden' : 'visible'}`}>
          <div className="flex justify-between">
            <div className="w-64">
              <LabelsSelect />
            </div>
            <div
              className="flex  items-center gap-1"
              onMouseEnter={handleToggle}
              onMouseLeave={handleToggle}
            >
              {visible ?
                <div className="absolute bg-black text-white text-sm rounded-md p-2 top-[-65px] left-[-55px] w-[240px]">
                  {`در صورت غیر فعال کردن این گزینه 
                 قبض های آپلود شده به صورت غیر فعال 
                در پنل نمایش داده می شوند.`}
                </div> : ''}
              <CustomSwitch title="فعال/غیرفعال قبض‌ها" enabled={enabled} onChange={setEnabled} />
              <InfoIcon width={'25px'} height={'25px'} />
            </div>
          </div>
          <div className="w-full py-4">
            <Upload
              className="flex flex-col justify-center w-full rounded-md h-64 gap-4 items-center border-dashed border-slate-300 border-2 pt-6"
              {...props}
            >
              <img src="/images/download.png" alt="upload" width={80} height={80} />
              <p className="paragraph-xs">لطفا فایل را انتخاب نمایید یا درگ و دراپ کنید.</p>
              <p className="text-xs">فرمت های پشتیبانی شده : XLS , XLSX</p>
            </Upload>
          </div>
        </div>
        {fileName && (<div className="px-10 py-4">
          <div className="border-2 rounded-md border-slate-200 mt-2 p-2 text-left">
            <div className="flex flex-row-reverse gap-1">
              <FileIcons/><p>{fileName || 'test'}</p>
            </div>
            <div className="h-2 flex w-full justify-center items-center" dir="ltr">
              <Line
                percent={percentage}
                strokeWidth={1}
                trailWidth={1}
                trailColor="#FFF"
                strokeColor={isUploading ? '#00416B' : '#07A734'}
              />
              <div className="flex w-4 h-4 ml-2">{isUploading ? <CloseLineIcon /> : <TickIcon />}</div>
            </div>
          </div>
          {recordCount && renderUploadData()}
        </div>)}

        {error && <p className="px-10 pb-4 text-red-500 text-sm">{error}</p>}
        <div className="flex items-center justify-start px-10 pb-6 gap-4">
          {/* <button className="btn-primary btn-rounded px-12 " type="button" onClick={() => setStarting(true)}>
            آپلود
          </button> */}
          <button className="btn-outline btn-rounded px-10" type="button" onClick={() => setOpen(false)}>
            انصراف
          </button>
          <a
            className="flex items-center bg-[#F5F7FD] text-primary btn-rounded px-10 text-xs"
            href="/assets/files/sabbod.xlsx"
            target="_blank"
          >
            فایل نمونه
          </a>
        </div>
      </div>
    </Modal>
  </>;
}
