import { CloseTwoIcon } from 'src/assets/icons';
import s from './notif-modal.module.css';
import { useNotificationQuery } from './notifications-api-slice';

export default function NotifDetailModal({ onClose, item }: any) {
  const { data } = useNotificationQuery(item?.id);
  return (
    <div className={s.detailModal}>
      <div className={s.modalHeader}>
        <span className="paragraph-bx">متن پیام</span>
        <div onClick={onClose} className={s.iconClose}>
          <CloseTwoIcon />
        </div>
      </div>
      <div className="p-4 overflow-auto h-[calc(100%-32px)]" dangerouslySetInnerHTML={{ __html: data?.data?.content }}></div>
    </div>
  );
}
