import qs from 'qs';
import { apiSlice } from 'src/services/api-slice';

export const drawApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    billTypes: builder.query({
      query: () => {
        return {
          url: '/bill/types/',
        };
      },
      providesTags: ['Bills'],
      transformResponse: ({ data }) => data,
    }),
    billRenew: builder.query({
      query: (id) => ({
        url: `/bill/renew/${id}`,
        method: 'GET',
      }),
      providesTags: ['Bills'],
    }),
    billDetail: builder.query({
      query: (id) => ({
        url: `/bill/detail/${id}`,
        method: 'GET',
      }),
      providesTags: ['Bills'],
    }),
    createBill: builder.mutation({
      query: (body) => ({
        url: '/bill/create/',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Bills'],
    }),
    editBill: builder.mutation({
      query: ({body,id}) => ({
        url: `/bill/edit/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['Bills'],
    }),
    billActivation: builder.mutation({
      query: (body) => ({
        url: `/bill/activation/${body.id}`,
        method: 'PATCH',
        body: { active: body.active },
      }),
      invalidatesTags: ['Bills'],
    }),
    createBillsFactor: builder.mutation({
      query: ({body}) => ({
        url: `/label/create-bills-factor/`,
        method: 'POST',
        body:{...body}
      }),
      invalidatesTags: ['Bills'],
    }),
    getInquiryHistory: builder.mutation({
      query: ({ id, params }) => ({
        url: `/bill/inquiry_history/${id}`,
        method: 'GET',
        params: params,
      }),
    }),
    billPayment: builder.mutation({
      query: (body) => ({
        url: '/payment/bill-payment/',
        method: 'POST',
        body: { ...body },
      }),
    }),
    walletBillPayment: builder.mutation({
      query: (body) => ({
        url: '/payment/wallet-payment/',
        method: 'POST',
        body: { ...body },
      }),
    }),
    getBillInfo: builder.mutation({
      query: (params:any) => ({
        url: `/payment/bill-payment-info/`,
        params: `${qs.stringify({...params}, {arrayFormat: 'repeat'})}`,
        method: 'GET',
      }),
    }),
    getPaymentMethods: builder.mutation({
      query: (params:any) => ({
        url: `/payment/payment-method-list/`,
        params: `${qs.stringify({...params}, {arrayFormat: 'repeat'})}`,
        method: 'GET',
      }),
    }),
    initialPayment: builder.mutation({
      query: (body:any) => ({
        url: `/payment/payment-initial/`,
        method: 'POST',
        body: { ...body },
      }),
    }),
    makePayment: builder.mutation({
      query: (body:any) => ({
        url: `/payment/make-payment/`,
        method: 'POST',
        body: { ...body },
      }),
    })
  })
});

export const {
  useBillTypesQuery,
  useLazyBillRenewQuery,
  useBillDetailQuery,
  useCreateBillMutation,
  useEditBillMutation,
  useBillActivationMutation,
  useCreateBillsFactorMutation,
  useGetInquiryHistoryMutation,
  useBillPaymentMutation,
  useWalletBillPaymentMutation,
  useGetBillInfoMutation,
  useGetPaymentMethodsMutation,
  useInitialPaymentMutation,
  useMakePaymentMutation
} = drawApiSlice;