import React, { useEffect, useState } from 'react';
import { select_options } from '../constants';
import { ArrowRightIcons, ArrowLeftIcons } from '../../../assets/icons';

const Pagination = ({ filters, setFilter, total }: any) => {
  const { page_number, page_size } = filters;
  const { total_page, total_row } = total;
  const [show_pages, setShowPages] = useState<any>([]);

  useEffect(() => {
    let _pages:any = []
    if (page_number)
      _pages = Array.from({ length: total_page }, (_, i) => i + 1).slice(((page_number - 3) >= 0 ? page_number - 3 : 0), page_number + 2)
    if ((page_number - 3) > 0)
      _pages.unshift(1)
    if ((page_number + 3) < total_page)
      _pages.push(total_page)
    setShowPages(_pages)
  }, [filters,total]);

  const handlePreviousPage = () => {
    setFilter({ ...filters, page_number: Math.min(page_number - 1, 1) });
  };

  const handleNextPage = () => {
    setFilter({ ...filters, page_number: Math.min(page_number + 1, total_page) });
  };

  const handleRowsPerPageChange = (event) => {
    setFilter({ ...filters, page_size: Number(event.target.value), page_number: 1 });
  };

  const handlePageChange = (pageNumber) => {
    setFilter({ ...filters, page_number: pageNumber });
  };

  return (
    <div className="mt-4 flex justify-between flex-row-reverse items-center">
      <div className="flex items-center ml-10 mb-5">
        <span className="text-[#949ABD] text-sm ml-2 w-[95px] h-[21px] font-medium items-center justify-center flex">
          {page_number * page_size > total_row ? total_row : page_number * page_size} آیتم از {total_row}
        </span>
        <select value={page_size} onChange={handleRowsPerPageChange} className="border text-sm rounded-md bg-white py-1 px-1 mx-2  ml-2 w-[75px] h-[30px]font-normal">
          {select_options.map((size) => (
            <option  key={size} value={size}>آیتم {size}</option>
          ))}
        </select>
        <button
          onClick={handlePreviousPage}
          disabled={page_number === 1}
          className={`border p-2 rounded-r-md h-8 w-8 font-normal text-sm flex items-center justify-center ${page_number === 1? '':''}`}
        >
         <ArrowRightIcons style={page_number === 1 ? {}: { stroke:'black' }}/>
        </button>
        <div className="flex items-center">
          {show_pages?.map((id, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(id)}
              className={`border p-2  w-8 h-8 font-normal text-sm ${page_number === id ? 'bg-[#181C32] text-white' : ''}`}
            >
              {id}
            </button>
          ))}
        </div>
        <button
          onClick={handleNextPage}
          disabled={page_number === total_page}
          className="border p-2  w-8 h-8 rounded-l-md text-sm font-normal flex items-center justify-center"
        >
          <ArrowLeftIcons style={page_number === total_page ? {}: { stroke:'black' }}/>
        </button>
      </div>
    </div>
  );
};

export default Pagination;