import { useEffect, useRef, useState } from 'react';
import { ProfileIcon, ProfileCircleIcon, LoginIcon, AccountIcon } from 'src/assets/icons';
import s from './profile.module.css';
import { useLogoutMutation } from 'src/features/auth/auth-api-slice';
import { Link, useNavigate } from 'react-router-dom';
import { useRequestProfileMutation } from '../../../../features/profile/profile-api-slice';

function Profile() {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLInputElement>(null);
  const refresh = localStorage.getItem('refresh');
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const [requestProfile] = useRequestProfileMutation();
  const [profileState, setProfileState] = useState(undefined);

  const handleLogout = async () => {
    try {
      await logout({ refresh_token: refresh }).unwrap();
      localStorage.removeItem('refresh');
      localStorage.removeItem('access');
      localStorage.removeItem('labels');
      navigate('/login');
    } catch (error) {
    }
  };

  const handleClick = (e: any) => {
    if (open && !menuRef.current?.contains(e.target)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const res = await requestProfile({}).unwrap();
      setProfileState(res);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div ref={menuRef}>
      <div
        onClick={() => setOpen(!open)}
        className="cursor-pointer"
      >
        <AccountIcon
          width={24}
          height={24}
        />
      </div>
      {open && (
        <div className={s.profile}>
          <ul className={s.profileItems}>
            <li className={s.itemProfile}>
              <div className="flex flex-col justify-center items-start pt-1 bg-gray-75 rounded px-2 h-[65px] mb-1 ">
                <div className={s.idProfile}>
                  <ProfileCircleIcon />
                  <p className="text-primary">{profileState?.first_name} {profileState?.last_name}</p>
                </div>
                <span className="block mr-5 text-primary font-normal">{profileState?.mobile_number}</span>
              </div>
            </li>
            <li className={`${s.profileUser} bg-gray-75 rounded mt-1`} onClick={() => setOpen(false)}>
              <Link to="/profile" className="flex gap-2">
                <ProfileIcon />
                <span>اطلاعات حساب کابری</span>
              </Link>
            </li>
            <li className={s.pItem} onClick={handleLogout}>
              <div className="flex gap-2">
                <LoginIcon />
                <span>خروج از حساب کاربری</span>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Profile;
