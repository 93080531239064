import { columnsProps } from '../../../components/table-wrapper/types';
import { dateFormatter, priceFormatter } from '../../../utils/helpers';
import { Popover } from '@headlessui/react';
import { ThreeDotsIcon } from '../../../assets/icons';
import ReportsBillPopOver from '../popover';
import ManagementDetailModal from '../detail-modal';

export const columns: columnsProps[] = [
  {
    title: 'نوع قبض', field: 'bill_type_logo', hasSort: false,
    renderCell: ({ bill_type_logo }) => <img className={'m-auto'} src={bill_type_logo} width={24} height={24} alt="" />,
  },
  { title: 'برچسب', field: 'label_name', hasSort: false },
  { title: 'عنوان', field: 'caption', hasSort: false },
  { title: 'شناسه قبض', field: 'bill_id', hasSort: false },
  { title: 'کد دوره', field: 'period_code', hasSort: false },
  {
    title: 'مبلغ قبض (ریال)', field: 'last_amount', hasSort: true,
    renderCell: ({ last_amount }) => priceFormatter(last_amount),
  },
  {
    title: 'آخرین مهلت پرداخت', field: 'payment_deadline', hasSort: true,
    renderCell: ({ payment_deadline }) => dateFormatter(payment_deadline),
  },
  {
    title: 'تاریخ آخرین استعلام', field: 'last_update', hasSort: true,
    renderCell: ({ last_update }) => dateFormatter(last_update),
  },
  { title: 'وضعیت پرداخت', field: 'payment_status', hasSort: false },
  {
    title: 'وضعیت', field: 'is_active', hasSort: false,
    renderCell: ({ is_active }) => is_active ? 'فعال' : 'غیر فعال',
  },
  {
    title: 'عملیات', field: '', hasSort: false,
    renderCell: (props,renew) => <div className={'flex ml-3'}>
      <ManagementDetailModal {...props} />
      <Popover className={'relative flex justify-center items-center'}>
        <Popover.Button >
          <ThreeDotsIcon />
        </Popover.Button>
        <Popover.Panel className={'absolute left-8 top-0 z-10'}>
          <ReportsBillPopOver id={props?.id} isActive={props?.is_active} item={props} renew={renew}/>
        </Popover.Panel>
      </Popover>
    </div>,
  },
];