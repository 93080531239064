import { useState } from 'react';
import { CalendarIcon, FilterSearchIcon } from '../../../assets/icons';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import classNames from 'classnames';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import gregorian from 'react-date-object/calendars/gregorian';
import { FilterProps } from '../types';
import Modal from '../../modal';
import Dropdown from '../../dropdown';

const isModal = (lenght: number, width: number) => lenght >= 4 || width <= 1024;

const View = ({ filterOptions, handleForm, filters }) => <>
  {filterOptions?.map(({ type, placeholder, name, options, api }, index) => {
    switch (type) {
      case 'string':
        return <input
          key={index} type={'text'} placeholder={placeholder} name={name}
          onChange={handleForm} value={filters[name] || ''}
          className="w-[285px] h-[40px] bg-white rounded-3xl border border-[#DCDCDC] text-sm "
        />;
      case 'number':
        return <input
          key={index} type={'number'} placeholder={placeholder} name={name}
          onChange={handleForm} value={filters[name] || ''}
          className="w-[285px] h-[40px] bg-white rounded-3xl border border-[#DCDCDC] text-sm "
        />;
      case 'select':
        return <Dropdown
          className={'w-[285px]'}
          options={options}
          placeholder={placeholder}
          handleForm={(e: any) => {
            handleForm({ target: { name: name, value: e.value } });
          }}
          key={index}
          isMulti={false}
        />;
      case 'multiselect':
        return <Dropdown
          isSearchable={true}
          className={'w-[285px]'}
          options={options}
          placeholder={placeholder}
          handleForm={(e: any) => {
            const ids = (e.length > 0 && e.map((item: any) => item?.value)) || [];
            handleForm({ target: { name: name, value: ids } });
          }}
          key={index}
          isMulti={true}
        />;
      case 'date':
        return <div className="relative w-[285px] ">
          <DatePicker
            calendar={persian}
            format="YYYY/MM/DD hh:mm:ss"
            locale={persian_fa}
            value={filters[name]?new DateObject(filters[name]).convert(persian, persian_fa):''}
            // @ts-ignore
            onChange={(date: any) => handleForm({
              target: {
                name: name,
                value: !date ? '' : new DateObject(date).convert(gregorian, gregorian_en).format('YYYY-MM-DD hh:mm:ss'),
              },
            })}
            plugins={[<TimePicker position="bottom" />]}
            minDate={'1402/06/15'}
            maxDate={new DateObject().subtract(1, 'day')}
            inputClass={classNames('w-[285px] parag-lg-b placeholder:text-gray-500 border rounded-full pr-4 h-10 bg-white text-right text-gray-500 border-gray-100')}
            placeholder={placeholder}
          />
          <CalendarIcon className="absolute top-2 left-3" />
        </div>;
    }
  })}
</>;

const Filter = ({ filterOptions, setFilters, filters }: FilterProps) => {
  const [open, setOpen] = useState(false);
  const [filterState ,setFilterState] = useState({});
  const [width] = useState(window.innerWidth);

  const handleForm = ({ target: { name, value } }) => {
    if (typeof value === 'boolean' || value) {
      if (isModal(filterOptions?.length, width))
        setFilterState({ ...filterState,...filters, [name]: value });
      else
        setFilters({ ...filters, [name]: value });
    } else {
      const _value = filters;
      delete _value[name];
      if (isModal(filterOptions?.length, width))
        setFilterState({ ...filterState, [name]: value });
      else
        setFilters({ ..._value, page_number: 1 });
    }
  };

  const handleModalFilter = () => {
    setFilters(filterState)
    setOpen(!open)
  }

  const handleRemoveFilters = () => {
    setFilters({
      page_number: 1,
      page_size: 10,
    });
    setOpen(undefined);
  };

  return <>
    {isModal(filterOptions?.length, width) ? <>
      <div className={open?'':'hidden'}>
        <Modal open={open !== undefined?true:false} onClose={() => setOpen(!open)}>
          <div className={'bg-white w-[650px] h-[380px] flex flex-col justify-between rounded-2xl'}>
            <h1 className={'mt-4 text-center'}>فیلتر ها</h1>
            <div className="grid grid-cols-2 grid-flow-row auto-cols-max gap-4 px-8 py-16">
              <View filters={filters} filterOptions={filterOptions} handleForm={handleForm} />
            </div>
            {isModal(filterOptions?.length, width) && <div className="flex flex-row-reverse">
              <button
                className={'border-2 border-primary rounded-full px-8 my-4 ml-4 h-[35px] flex items-center justify-center'}
                onClick={() => setOpen(!open)}>انصراف
              </button>
              <button
                className={'bg-primary text-white rounded-full px-8 my-4 ml-2 h-[35px] flex items-center justify-center'}
                onClick={() => handleModalFilter()}>اعمال فیلتر
              </button>
              <button
                className={'border-2 border-primary rounded-full px-8 my-4 ml-2 h-[35px] flex items-center justify-center'}
                onClick={() => handleRemoveFilters()}>حذف فیلترها
              </button>
            </div>}
          </div>
        </Modal>
      </div>
      <button
        className={'border-2 border-primary rounded-full px-8 ml-4 mb-2 h-[35px] flex items-center justify-center'}
        onClick={() => setOpen(!open)}><FilterSearchIcon />فیلتر
      </button>
    </> : <div className={'flex gap-2 mb-4'}>
      <View filters={filters} filterOptions={filterOptions} handleForm={handleForm} />
    </div>}
  </>;
};

export default Filter;