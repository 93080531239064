import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function ApexChartDonut({ data }: any) {
  const series = data?.chart_info?.map((item: any) => item?.bills_count || 0) || [];
  const options: ApexOptions = {
    chart: {
      width: '100%',
      type: 'pie',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      offsetY: 20,
      width: 138,
      fontSize: '12px',
      fontFamily: 'Vazirmatn',
      fontWeight: 400,
      labels: {
        colors: '#718096',
        useSeriesColors: false,
      },
      markers: {
        offsetX: 7,
      },
    },
    colors: data?.chart_info?.map((item: any) => item?.bill_type__color_code || 0) || [],
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetY: -5,
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 500,
              fontFamily: 'Vazirmatn',
              color: undefined,
              offsetY: 5,
              formatter: function(val) {
                return val;
              },
            },
            total: {
              show: true,
              label: 'Total',
              color: '#373d3f',
            },
          },
        },
      },
    },
    labels: data?.chart_info?.map((item: any) => item?.bill_type__name.toString()) || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <>
      <p className="parag-xl-b text-gray-800">تعداد قبوض پرداخت نشده</p>
      <div id="chart3" className="flex justify-between">
        <div className="parag-lg flex flex-col text-gray-300 mt-6 space-y-3">
          {data?.chart_info ? data?.chart_info?.map((item: any, index: number) =>
            <span key={`${item.bill_type__name}-${index}`} className={'flex justify-start items-center ml-2'}>
              <span className={`flex justify-center items-baseline text-black ml-1`}>
                <div className={`w-2 h-2 rounded-full ml-1`} style={{ backgroundColor: `${options.colors[index]}` }} />
                {item.bills_count}
              </span>
              {item?.bill_type__name}
            </span>,
          ) : ''}
        </div>
        {series?.length ?
          <ReactApexChart options={options} series={series || []} type="donut" height={150} width={115} /> :
          <p className={'w-full text-center my-16'}>داده ای موجود نمی باشد</p>
        }
      </div>
    </>
  );
}

export default ApexChartDonut;
