import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';

const managementSlice = createSlice({
  name: 'management',
  initialState: {
    filters: {
      page_number: 1,
      page_size: 10,
    },
  },
  reducers: {
    setManagementFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    updateManagementFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
  },
});

export const { setManagementFilters ,updateManagementFilters } = managementSlice.actions;
export default managementSlice.reducer;

export const selectManagementFilters = (state: RootState) => state.management.filters;
