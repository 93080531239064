import Joi from 'joi';

export const billSchema = Joi.object({
  caption: Joi.string().required().label('name').messages({
    'string.empty': `عنوان الزامیست`,
    'any.required': `عنوان الزامیست`,
    'string.pattern.base': `عنوان صحیح نمی‌باشد`,
  }),
  bill_type_id: Joi.required().messages({
    'string.empty': `نوع قبض الزامیست`,
    'any.required': `نوع قبض الزامیست`,
    'string.pattern.base': `نوع قبض صحیح نمی‌باشد`,
  }),
  bill_id: Joi.number().integer().required().messages({
    'number.empty': `شناسه قبض الزامیست`,
    'number.base': `یک مقدار عددی لاتین معتبر وارد کنید`,
    'any.required': `شناسه قبض الزامیست`,
    'number.pattern.base': `شناسه قبض صحیح نمی‌باشد`,
  }),
  label_id: Joi.required().label('name').messages({
    'number.empty': `برچسب الزامیست`,
    'any.required': `برچسب الزامیست`,
    'number.pattern.base': `برچسب صحیح نمی‌باشد`,
  }),
  description: Joi.string().allow('', null),
  enable_midterm_payment: Joi.required().messages({
    'string.empty': `نوع قبض الزامیست`,
    'any.required': `نوع قبض الزامیست`,
    'string.pattern.base': `نوع قبض صحیح نمی‌باشد`,
  }),
});

export const schema = Joi.object({
  first_name: Joi.string().required().messages({
    'string.empty': 'نام الزامی است',
  }),
  last_name: Joi.string().required().messages({
    'string.empty': 'نام خانوادگی الزامی است',
  }),
  mobile: Joi.string().required().messages({
    'string.empty': 'موبایل الزامی است',
  }),
  phone: Joi.string().allow('', null).messages({
    'string.empty': 'تلفن الزامی است',
  }),
  address: Joi.string().allow('', null).messages({
    'string.empty': 'آدرس الزامی است',
  }),
  password: Joi.string().allow('', null).messages({
    'string.empty': 'رمز عبور الزامی است',
  }),
  balance: Joi.number().allow('', null).messages({
    'number.empty': 'کیف پول الزامی است',
  }),
  description: Joi.string().allow('', null).messages({
    'string.empty': 'توضیحات الزامی است',
  }),
  can_order: Joi.boolean().messages({
    'string.empty': 'قابلیت سفارش الزامی است',
  }),
  role: Joi.any().messages({
    'any.empty': 'نوع کاربری الزامی است',
  }),
  active: Joi.boolean().messages({
    'string.empty': 'فعال الزامی است',
  }),
});
